import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import clsx from 'clsx'

import { startAutomationMachineForRecommendation } from '$extensionSrc/automations/automationStageMachine'
import ActionButton, {
  ActionButtonType,
} from '$extensionSrc/components/ActionButton'
import GrayBars from '$extensionSrc/icons/gray-bars.svg'
import RepostIcon from '$extensionSrc/icons/repost-icon.svg'
import { sendGetCurrentTabUrl } from '$extensionSrc/utils/communication'
import {
  AutomationType,
  PLATFORM,
  TwitterRecommendationKey,
} from '$extensionSrc/utils/enums'

import styles from './SelectTwitterPost.module.scss'

export default function SelectTwitterPost({
  defaultTaskTitle,
  hasError,
  locked,
  platformUserId,
  postAuthorName,
  postAuthorUsername,
  postContent,
  postRepostCount,
}: {
  defaultTaskTitle: ReactNode
  hasError: boolean
  locked: boolean
  platformUserId: string
  postAuthorName: string
  postAuthorUsername: string
  postContent: string
  postRepostCount: string
}) {
  const [isReloading, setIsReloading] = useState(false)

  const handleSelectPost = useCallback(async () => {
    const res = await sendGetCurrentTabUrl()

    setIsReloading(true)
    startAutomationMachineForRecommendation(
      PLATFORM.TWITTER,
      platformUserId,
      TwitterRecommendationKey.TWITTER_BLOCK_REPOSTERS,
      AutomationType.GATHER,
      {
        [TwitterRecommendationKey.TWITTER_BLOCK_REPOSTERS]: {
          postUrl: res?.currentTabUrl,
        },
      },
    )
  }, [platformUserId])

  useEffect(() => {
    if (isReloading) {
      setTimeout(() => {
        setIsReloading(false)
      }, 500)
    }
  }, [isReloading])

  const repostCount = postRepostCount || 0

  /* eslint-disable no-nested-ternary */
  return (
    <div className={clsx(styles.selectTwitterPost)}>
      <div className="body1 black bold">
        {postAuthorName && postAuthorUsername
          ? 'Block from this post:'
          : defaultTaskTitle}
      </div>
      <div className={clsx(styles.postInfo)}>
        {isReloading ? (
          <GrayBars aria-hidden className={clsx(styles.loadingContent)} />
        ) : postAuthorName && postAuthorUsername ? (
          <>
            <div>
              <div className="body3">
                <span className="bold">{postAuthorName}</span>
                <span className="gray"> @{postAuthorUsername}</span>
              </div>
              <div className={clsx(styles.postContent, 'body3')}>
                {postContent}
              </div>
            </div>
            <div className={styles.postRepostCount}>
              <RepostIcon className={clsx(styles.repostIcon, styles.hasPost)} />
              <span className={clsx('small-label1', styles.hasPost)}>
                {repostCount}
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="body3">
              With the post open in this browser tab, click
              <span className="bold"> select current post</span> below.
            </div>
            <div className={clsx(styles.postRepostCount, 'small-label1')}>
              <RepostIcon className={clsx(styles.repostIcon, styles.noPost)} />
              <span className={clsx('small-label1', styles.noPost)}>0</span>
            </div>
          </>
        )}
      </div>
      {hasError && (
        <div className={clsx(styles.errorHelper, 'body3')}>
          No post detected. To go to the post page, click on it from your
          timeline. The url should look something like{' '}
          <span className="bold">https://x.com/username/status/#########.</span>
        </div>
      )}
      <ActionButton
        ariaLabel="Select current post"
        disabled={locked}
        type={ActionButtonType.SECONDARY}
        onClick={handleSelectPost}
      >
        {postAuthorName && postAuthorUsername ? 'Change to' : 'Select'} current
        post
      </ActionButton>
    </div>
  )
}
