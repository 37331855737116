import { SnapchatRecommendationKey } from '../../utils/enums'
import metadata from '../metadata/snapchat.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import specs from '../specs/snapchat.yaml'

const SNAPCHAT_RECOMMENDATION_KEYS = [
  SnapchatRecommendationKey.SNAPCHAT_ACTIVITY_INDICATOR,
  SnapchatRecommendationKey.SNAPCHAT_AD_PREFERENCES,
  SnapchatRecommendationKey.SNAPCHAT_CONTACT_ME,
  SnapchatRecommendationKey.SNAPCHAT_FAMILY_CENTER,
  SnapchatRecommendationKey.SNAPCHAT_LOCATION,
  SnapchatRecommendationKey.SNAPCHAT_NOTIFICATIONS,
  SnapchatRecommendationKey.SNAPCHAT_QUICK_ADD,
  SnapchatRecommendationKey.SNAPCHAT_SCREEN_TIME,
  SnapchatRecommendationKey.SNAPCHAT_TWO_FACTOR_AUTHENTICATION,
]

const SNAPCHAT_RECOMMENDATIONS_REGISTRY: Record<
  SnapchatRecommendationKey,
  Recommendation
> = Object.fromEntries(
  Object.keys(SnapchatRecommendationKey).map((k) => [
    SnapchatRecommendationKey[k],
    { ...parseSpec(specs[k]), key: k },
  ]),
)

export const snapchatRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => SNAPCHAT_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () => SNAPCHAT_RECOMMENDATION_KEYS,
  getChecklistKeys: () => SNAPCHAT_RECOMMENDATION_KEYS,
  getContentTaskKeys: () => [],
  getExperienceTaskKeys: () => [],
  getRecommendationsRegistry: () => SNAPCHAT_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
