import { useMemo } from 'react'

import { PLATFORM } from '../../utils/enums'

const PLATFORM_TO_DISPLAY_NAME: Record<PLATFORM, string> = {
  [PLATFORM.FACEBOOK]: 'Facebook',
  [PLATFORM.GOOGLE]: 'Google',
  [PLATFORM.INSTAGRAM]: 'Instagram',
  [PLATFORM.LINKEDIN]: 'LinkedIn',
  [PLATFORM.REDDIT]: 'Reddit',
  [PLATFORM.SNAPCHAT]: 'Snapchat',
  [PLATFORM.STRAVA]: 'Strava',
  [PLATFORM.TIKTOK]: 'TikTok',
  [PLATFORM.TWITTER]: 'X (Twitter)',
  [PLATFORM.VENMO]: 'Venmo',
  [PLATFORM.YOUTUBE]: 'YouTube',
}

export function getPlatformName(
  platform: PLATFORM | null | undefined,
): string | null | undefined {
  if (platform === undefined) {
    return undefined
  }

  if (!(platform in PLATFORM_TO_DISPLAY_NAME)) {
    return null
  }

  return PLATFORM_TO_DISPLAY_NAME[platform]
}

export function usePlatformName(
  platform: PLATFORM | null | undefined,
): string | null | undefined {
  return useMemo(() => getPlatformName(platform), [platform])
}
