import {
  RedditRecommendationKey,
  RecommendationTaskType,
} from '../../utils/enums'
import metadata from '../metadata/reddit.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import specs from '../specs/reddit.yaml'

const REDDIT_RECOMMENDATION_KEYS = [
  RedditRecommendationKey.REDDIT_CONTENT_COMMUNITY_VISIBILITY,
  RedditRecommendationKey.REDDIT_DISCOVERABILITY,
  RedditRecommendationKey.REDDIT_CHAT,
  RedditRecommendationKey.REDDIT_NOTIFICATIONS,
  RedditRecommendationKey.REDDIT_EMAIL_NOTIFICATIONS,
  RedditRecommendationKey.REDDIT_THIRD_PARTY_ACCESS,
  RedditRecommendationKey.REDDIT_PERSONALIZED_ADS,
  RedditRecommendationKey.REDDIT_2FA,
  RedditRecommendationKey.REDDIT_PUBLIC_VIEW,
]

const REDDIT_RECOMMENDATIONS_REGISTRY: Record<
  RedditRecommendationKey,
  Recommendation
> = {
  [RedditRecommendationKey.REDDIT_DISCOVERABILITY]: {
    ...parseSpec(specs[RedditRecommendationKey.REDDIT_DISCOVERABILITY]),
    key: RedditRecommendationKey.REDDIT_DISCOVERABILITY,
  },
  [RedditRecommendationKey.REDDIT_CONTENT_COMMUNITY_VISIBILITY]: {
    ...parseSpec(
      specs[RedditRecommendationKey.REDDIT_CONTENT_COMMUNITY_VISIBILITY],
    ),
    key: RedditRecommendationKey.REDDIT_CONTENT_COMMUNITY_VISIBILITY,
  },
  [RedditRecommendationKey.REDDIT_2FA]: {
    ...parseSpec(specs[RedditRecommendationKey.REDDIT_2FA]),
    hasAutomatedGather: true,
    isAutomated: false,
    key: RedditRecommendationKey.REDDIT_2FA,
  },
  [RedditRecommendationKey.REDDIT_CHAT]: {
    ...parseSpec(specs[RedditRecommendationKey.REDDIT_CHAT]),
    key: RedditRecommendationKey.REDDIT_CHAT,
  },
  [RedditRecommendationKey.REDDIT_THIRD_PARTY_ACCESS]: {
    ...parseSpec(specs[RedditRecommendationKey.REDDIT_THIRD_PARTY_ACCESS]),
    key: RedditRecommendationKey.REDDIT_THIRD_PARTY_ACCESS,
  },
  [RedditRecommendationKey.REDDIT_PERSONALIZED_ADS]: {
    ...parseSpec(specs[RedditRecommendationKey.REDDIT_PERSONALIZED_ADS]),
    key: RedditRecommendationKey.REDDIT_PERSONALIZED_ADS,
  },
  [RedditRecommendationKey.REDDIT_NOTIFICATIONS]: {
    ...parseSpec(specs[RedditRecommendationKey.REDDIT_NOTIFICATIONS]),
    key: RedditRecommendationKey.REDDIT_NOTIFICATIONS,
  },
  [RedditRecommendationKey.REDDIT_EMAIL_NOTIFICATIONS]: {
    ...parseSpec(specs[RedditRecommendationKey.REDDIT_EMAIL_NOTIFICATIONS]),
    key: RedditRecommendationKey.REDDIT_EMAIL_NOTIFICATIONS,
  },
  [RedditRecommendationKey.REDDIT_PUBLIC_VIEW]: {
    ...parseSpec(specs[RedditRecommendationKey.REDDIT_PUBLIC_VIEW]),
    key: RedditRecommendationKey.REDDIT_PUBLIC_VIEW,
  },
}

export const redditRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => REDDIT_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () => REDDIT_RECOMMENDATION_KEYS,
  getChecklistKeys: () =>
    REDDIT_RECOMMENDATION_KEYS.filter(
      (key) => !REDDIT_RECOMMENDATIONS_REGISTRY[key].taskType,
    ),
  getContentTaskKeys: () =>
    REDDIT_RECOMMENDATION_KEYS.filter(
      (key) =>
        REDDIT_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.CONTENT,
    ),
  getExperienceTaskKeys: () =>
    REDDIT_RECOMMENDATION_KEYS.filter(
      (key) =>
        REDDIT_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.EXPERIENCE,
    ),
  getRecommendationsRegistry: () => REDDIT_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
