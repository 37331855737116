import {
  AdditionalMachineData,
  Machine,
  MachineContext,
  Stage,
  StageMachineDef,
  StageRegistry,
  UserContext,
} from './interfaces'
import * as state from '../state'
import * as communication from '../utils/communication'
import * as enums from '../utils/enums'
import { _debug } from '../utils/logging'

// Local storage management
export async function machineCleanUp(machineName) {
  await state.setMachineContext(machineName, null)
}

// Generic "constructor" function for creating a stage machine

export function createStageMachine({
  machineName,
  initialStageKey,
  createStagesAndRegistryFn,
}: StageMachineDef): Machine {
  let stageRegistry: StageRegistry
  let currentStage: Stage

  async function transitionFromStage(
    stage: Stage,
    triggeringEventType: enums.TriggeringEventType,
    machineContext: MachineContext,
    userContext: UserContext,
    additionalMachineData: AdditionalMachineData,
  ) {
    const nextTransition = stage?.transitions[triggeringEventType]
    if (!nextTransition) {
      _debug(`next transition not found in stage transitions`)
      return
    }
    _debug(
      `transition from ${currentStage.key} on event ${triggeringEventType}`,
      'finite stage machine',
      'd',
    )

    const targetStageKey = nextTransition.getTargetStageKey(machineContext)
    _debug(targetStageKey, 'finite stage machine', 'd')
    if (targetStageKey === null || targetStageKey === undefined) {
      throw new Error(`targetStageKey for transition is null or undefined`)
    }
    const nextStage = stageRegistry[targetStageKey]
    if (!nextStage) {
      _debug(
        `next stage for transitionKey ${targetStageKey} is not found in registry`,
      )
      return
    }

    _debug(`nextStage: ${nextStage.key}`, 'finite stage machine', 'd')

    await stage.actions.onExit(
      machineContext,
      userContext,
      additionalMachineData,
    )
    if (nextTransition.action) {
      await nextTransition.action(additionalMachineData)
    }

    const { stageKeyHistoryStack } = machineContext
    stageKeyHistoryStack.push(nextStage.key)

    const updatedMachineContext = {
      machineName,
      currentStageKey: nextStage.key,
      stageKeyHistoryStack,
      redirectRetryCount: 0,
    }
    await state.updateMachineContext(machineName, updatedMachineContext)
    await communication.sendSetMachineSignal(machineName, null)

    currentStage = nextStage

    /**
     * If the next stage is the FINAL stage, clear the machine context from
     * storage (will not erase the "updated machine context" in the current
     * scope).
     *
     * Note that we still need to run the next stage's onEnter function; allows
     * for stage machine specific clean up code to run.
     *
     * It is also important that machineCleanUp runs first, as other cleanup
     * tasks may include, e.g., closing the tab.
     */
    if (nextStage.type === enums.StageType.FINAL) {
      await machineCleanUp(machineName)
    }

    await nextStage.actions.onEnter(
      updatedMachineContext,
      userContext,
      additionalMachineData,
    )
  }

  async function transition(
    triggeringEventType: enums.TriggeringEventType,
    machineContext: MachineContext,
    userContext: UserContext,
    additionalMachineData: AdditionalMachineData,
  ): Promise<void> {
    const { currentStageKey } = machineContext
    currentStage = stageRegistry[currentStageKey]

    return transitionFromStage(
      currentStage,
      triggeringEventType,
      machineContext,
      userContext,
      additionalMachineData,
    )
  }

  async function onTriggeringEvent(
    triggeringEventType: enums.TriggeringEventType,
    machineContext: MachineContext,
    userContext: UserContext,
    additionalMachineData: AdditionalMachineData,
  ) {
    transition(
      triggeringEventType,
      machineContext,
      userContext,
      additionalMachineData,
    )
  }

  function isInitialized(machineContext: MachineContext): boolean {
    const { currentStageKey } = machineContext

    return !(
      Object.keys(machineContext).length === 0 || currentStageKey === undefined
    )
  }

  async function initialize(): Promise<MachineContext> {
    const initialMachineContext = {
      machineName,
      currentStageKey: initialStageKey,
      stageKeyHistoryStack: [initialStageKey],
      redirectRetryCount: 0,
    }
    await state.updateMachineContext(machineName, initialMachineContext)
    return initialMachineContext
  }

  // Either initialize or load the machine from `machineContext`
  async function load(machineContext: MachineContext): Promise<MachineContext> {
    let { currentStageKey } = machineContext

    let updatedMachineContext = null
    if (!isInitialized(machineContext)) {
      updatedMachineContext = await initialize()
      currentStageKey = updatedMachineContext?.currentStageKey
    }

    // Ideally we could just pass the stage registry into the machine
    // but the stages need a reference to the `onTriggeringEvent`
    // function that is defined within the machine
    stageRegistry = createStagesAndRegistryFn(onTriggeringEvent)

    currentStage = stageRegistry[currentStageKey]
    return updatedMachineContext || machineContext
  }

  async function enterCurrentStage(
    machineContext: MachineContext,
    userContext: UserContext,
    additionalMachineData: AdditionalMachineData,
  ) {
    await currentStage.actions.onEnter(
      machineContext,
      userContext,
      additionalMachineData,
    )
  }

  const machine = {
    currentStage,
    enterCurrentStage,
    load,
    onTriggeringEvent,
    stageRegistry,
  }

  return machine
}
