import React from 'react'

import { useRequest } from 'ahooks'
import clsx from 'clsx'

import { dayInMs } from '$extensionSrc/utils/dateUtils'

import {
  getDateRange,
  getIsoDateString,
  getPriorSunday,
} from '../utils/dateHelpers'
import { getBulkRequestUrlForKey } from '../utils/metricsApiRequests'

import './HomePageMetrics.scss'

function useMetricsDataRequestHook(
  requestKeys: string[],
  options: { startDateAsStr?: string; endDateAsStr?: string },
) {
  return useRequest(async () => {
    const response = await fetch(getBulkRequestUrlForKey(requestKeys, options))
    if (response.status === 200) {
      const data: {
        [key: string]: {
          timeseries: { date: string; value: number }[]
          lastUpdatedOn: string
        }
      } = await response.json()

      const transformedData = Object.fromEntries(
        Object.entries(data).map(([key, { timeseries, lastUpdatedOn }]) => [
          key,
          {
            timeseries: Object.fromEntries(
              timeseries.map(({ date, value }) => [
                getIsoDateString(new Date(date)),
                value,
              ]),
            ),
            lastUpdatedOn: new Date(lastUpdatedOn),
          },
        ]),
      )

      return transformedData
    }
    return {}
  })
}

function aggRecordsMatchingDates(
  records: { [datestring: string]: number },
  dates: Date[],
) {
  return dates
    .map(getIsoDateString)
    .map((dateString) => records[dateString] || 0)
    .reduce((a, b) => a + b, 0)
}

function MetricsBox({
  title,
  thisWeek,
  thisTimeLastWeek,
  lastWeek,
  lastUpdatedOn,
}) {
  const olderThanOneDay =
    new Date().setHours(0, 0, 0, 0) -
      new Date(lastUpdatedOn).setHours(0, 0, 0, 0) >
    dayInMs

  return (
    <div className="MetricsBox">
      <h2>{title}</h2>
      <div className="this-week">
        <span className={clsx(olderThanOneDay && 'red')}>
          <span className="white">{olderThanOneDay && '*'}</span>
          {thisWeek}
          <span>{olderThanOneDay && '*'}</span>
        </span>
      </div>
      <div className="last-week body3">
        <div>This time last week: {thisTimeLastWeek}</div>
        <div>Last week (total): {lastWeek}</div>
        <div>&nbsp;</div>
        <div className={clsx(olderThanOneDay && 'red')}>
          Last updated on: {lastUpdatedOn}
        </div>
        {olderThanOneDay && (
          <div className="red">
            &nbsp;&nbsp;&nbsp;&nbsp;*needs to be updated
          </div>
        )}
      </div>
    </div>
  )
}

export default function HomePageMetrics() {
  const today = new Date()

  const endOfPrevWeek = getPriorSunday(today)
  const startOfPrevWeek = new Date(endOfPrevWeek.getTime() - 6 * dayInMs)
  const startOfCurWeek = new Date(endOfPrevWeek.getTime() + dayInMs)

  const { data, loading } = useMetricsDataRequestHook(
    [
      'installs_chrome',
      'installs_firefox',
      'installs_edge',
      'n_recommendations_completed',
      'n_settings_completed',
      'n_tool_runs_completed',
      'n_active_extension_users',
      'n_first_time_active_extension_users',
    ],
    {
      startDateAsStr: getIsoDateString(startOfPrevWeek),
      endDateAsStr: getIsoDateString(today),
    },
  )

  const {
    timeseries: installChromeTs,
    lastUpdatedOn: installChromeLastUpdatedOn,
  } = data?.installs_chrome || {}
  const {
    timeseries: installFirefoxTs,
    lastUpdatedOn: installFirefoxLastUpdatedOn,
  } = data?.installs_firefox || {}
  const { timeseries: installEdgeTs, lastUpdatedOn: installEdgeLastUpdatedOn } =
    data?.installs_edge || {}
  const {
    timeseries: nRecommendationsTs,
    lastUpdatedOn: nRecommendationsLastUpdatedOn,
  } = data?.n_recommendations_completed || {}
  const {
    timeseries: nSettingsUpdatesTs,
    lastUpdatedOn: nSettingsUpdatesLastUpdatedOn,
  } = data?.n_settings_completed || {}
  const { timeseries: nToolsRunTs, lastUpdatedOn: nToolsRunLastUpdatedOn } =
    data?.n_tool_runs_completed || {}
  const { timeseries: activeUserTs, lastUpdatedOn: activeUserLastUpdatedOn } =
    data?.n_active_extension_users || {}
  const {
    timeseries: firstTimeActiveUserTs,
    lastUpdatedOn: firstTimeActiveUserLastUpdatedOn,
  } = data?.n_first_time_active_extension_users || {}

  // Add some stuff up
  if (
    !loading &&
    installChromeTs &&
    installFirefoxTs &&
    installEdgeTs &&
    nRecommendationsTs &&
    nSettingsUpdatesTs &&
    nToolsRunTs &&
    activeUserTs &&
    firstTimeActiveUserTs
  ) {
    const lastWeeksDates = getDateRange(startOfPrevWeek, endOfPrevWeek)
    const thisTimeLastWeeksDates = getDateRange(
      startOfPrevWeek,
      new Date(today.getTime() - 7 * dayInMs),
    )
    const thisWeeksDates = getDateRange(startOfCurWeek, today)

    const installsLastWeek =
      aggRecordsMatchingDates(installChromeTs, lastWeeksDates) +
      aggRecordsMatchingDates(installFirefoxTs, lastWeeksDates) +
      aggRecordsMatchingDates(installEdgeTs, lastWeeksDates)

    const installsThisTimeLastWeek =
      aggRecordsMatchingDates(installChromeTs, thisTimeLastWeeksDates) +
      aggRecordsMatchingDates(installFirefoxTs, thisTimeLastWeeksDates) +
      aggRecordsMatchingDates(installEdgeTs, thisTimeLastWeeksDates)

    const installsThisWeek =
      aggRecordsMatchingDates(installChromeTs, thisWeeksDates) +
      aggRecordsMatchingDates(installFirefoxTs, thisWeeksDates) +
      aggRecordsMatchingDates(installEdgeTs, thisWeeksDates)

    const nRecommendationsLastWeek = aggRecordsMatchingDates(
      nRecommendationsTs,
      lastWeeksDates,
    )

    const nRecommendationsThisTimeLastWeek = aggRecordsMatchingDates(
      nRecommendationsTs,
      thisTimeLastWeeksDates,
    )

    const nRecommendationsThisWeek = aggRecordsMatchingDates(
      nRecommendationsTs,
      thisWeeksDates,
    )

    const nSettingsUpdatesLastWeek = aggRecordsMatchingDates(
      nSettingsUpdatesTs,
      lastWeeksDates,
    )

    const nSettingsUpdatesThisTimeLastWeek = aggRecordsMatchingDates(
      nSettingsUpdatesTs,
      thisTimeLastWeeksDates,
    )

    const nSettingsUpdatesThisWeek = aggRecordsMatchingDates(
      nSettingsUpdatesTs,
      thisWeeksDates,
    )

    const nToolsRunLastWeek = aggRecordsMatchingDates(
      nToolsRunTs,
      lastWeeksDates,
    )

    const nToolsRunThisTimeLastWeek = aggRecordsMatchingDates(
      nToolsRunTs,
      thisTimeLastWeeksDates,
    )

    const nToolsRunThisWeek = aggRecordsMatchingDates(
      nToolsRunTs,
      thisWeeksDates,
    )

    const activeUsersLastWeek = aggRecordsMatchingDates(
      activeUserTs,
      lastWeeksDates,
    )

    const activeUsersThisTimeLastWeek = aggRecordsMatchingDates(
      activeUserTs,
      thisTimeLastWeeksDates,
    )

    const activeUsersThisWeek = aggRecordsMatchingDates(
      activeUserTs,
      thisWeeksDates,
    )

    const firstTimeActiveUsersLastWeek = aggRecordsMatchingDates(
      firstTimeActiveUserTs,
      lastWeeksDates,
    )

    const firstTimeActiveUsersThisTimeLastWeek = aggRecordsMatchingDates(
      firstTimeActiveUserTs,
      thisTimeLastWeeksDates,
    )

    const firstTimeActiveUsersThisWeek = aggRecordsMatchingDates(
      firstTimeActiveUserTs,
      thisWeeksDates,
    )

    const installLastUpdatedOn = new Date(
      Math.max(
        installChromeLastUpdatedOn.getTime(),
        installFirefoxLastUpdatedOn.getTime(),
        installEdgeLastUpdatedOn.getTime(),
      ),
    )

    return (
      <div className="HomePageMetrics">
        <div className="MetricsRow">
          <MetricsBox
            title="Installs"
            thisWeek={installsThisWeek}
            thisTimeLastWeek={installsThisTimeLastWeek}
            lastWeek={installsLastWeek}
            lastUpdatedOn={installLastUpdatedOn.toDateString()}
          />
          <MetricsBox
            title="Active Users"
            thisWeek={activeUsersThisWeek}
            thisTimeLastWeek={activeUsersThisTimeLastWeek}
            lastWeek={activeUsersLastWeek}
            lastUpdatedOn={activeUserLastUpdatedOn.toDateString()}
          />
          <MetricsBox
            title="First Time Active Users"
            thisWeek={firstTimeActiveUsersThisWeek}
            thisTimeLastWeek={firstTimeActiveUsersThisTimeLastWeek}
            lastWeek={firstTimeActiveUsersLastWeek}
            lastUpdatedOn={firstTimeActiveUserLastUpdatedOn.toDateString()}
          />
        </div>
        <div className="MetricsRow">
          <MetricsBox
            title="Features"
            thisWeek={nRecommendationsThisWeek}
            thisTimeLastWeek={nRecommendationsThisTimeLastWeek}
            lastWeek={nRecommendationsLastWeek}
            lastUpdatedOn={nRecommendationsLastUpdatedOn.toDateString()}
          />
          <MetricsBox
            title="Settings Updates"
            thisWeek={nSettingsUpdatesThisWeek}
            thisTimeLastWeek={nSettingsUpdatesThisTimeLastWeek}
            lastWeek={nSettingsUpdatesLastWeek}
            lastUpdatedOn={nSettingsUpdatesLastUpdatedOn.toDateString()}
          />
          <MetricsBox
            title="Tools Run"
            thisWeek={nToolsRunThisWeek}
            thisTimeLastWeek={nToolsRunThisTimeLastWeek}
            lastWeek={nToolsRunLastWeek}
            lastUpdatedOn={nToolsRunLastUpdatedOn.toDateString()}
          />
        </div>
      </div>
    )
  }
}
