import React from 'react'

import { ReviewButton } from '$extensionSrc/components/Buttons'
import CheckmarkDone from '$extensionSrc/icons/checkmark-done-icon.svg'
import CheckmarkNotDone from '$extensionSrc/icons/checkmark-not-done-icon.svg'

import './BatchRow.scss'

export default function BatchRow({
  isUpdated,
  text,
  photoDetails,
  previouslyUpdated,
  onClick,
}) {
  const statusIcon = isUpdated ? <CheckmarkDone /> : <CheckmarkNotDone />

  return (
    <div className="BatchRow">
      <div className="status-icon">{statusIcon}</div>
      <div className="body2 black body-text">{text}</div>
      <div className="review-button">
        <ReviewButton
          onClick={() => onClick(photoDetails, text, previouslyUpdated)}
        />
      </div>
    </div>
  )
}
