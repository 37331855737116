/* eslint-disable no-continue */
import BuildConfig from '$extensionSrc/buildConfig'
import { STORAGE_KEY } from '$extensionSrc/utils/enums'
import * as localStorage from '$extensionSrc/utils/localStorage'

let instantiated: boolean = false

const networkSpeedLookup: Record<string, number> = {}

type Speed = {
  network?: number
  pageLoad?: number
}

export async function getSpeed(): Promise<Speed | null> {
  const speed = await localStorage.getSingle(STORAGE_KEY.SPEED)
  return speed || null
}

async function setNetwork(network: number): Promise<void> {
  const currentSpeed = await getSpeed()
  return localStorage.set({
    [STORAGE_KEY.SPEED]: {
      ...(currentSpeed || {}),
      network,
    },
  })
}

async function setPageLoad(pageLoad: number): Promise<void> {
  const currentSpeed = await getSpeed()
  return localStorage.set({
    [STORAGE_KEY.SPEED]: {
      ...(currentSpeed || {}),
      pageLoad,
    },
  })
}

async function unsetSpeed(): Promise<void> {
  return localStorage.remove(STORAGE_KEY.SPEED)
}

// (We're not importing _debug here because it creates a dependency cycle, and
// it's more important that $extensionSrc/utils/logging.ts imports this file
// than the other way around.)
function log(s: string): void {
  // Only log in development mode
  if (BuildConfig.REACT_APP_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug(`%c${s}`, 'background: #81cf61; color: #000000')
  }
}

export const handlePerformanceObservations: PerformanceObserverCallback =
  async (list) => {
    const performanceResourceTimings: PerformanceResourceTiming[] = list
      .getEntries()
      .filter((e) => e.entryType === 'resource') as PerformanceResourceTiming[]

    for (const prt of performanceResourceTimings) {
      if (prt.transferSize === 0) {
        continue
      }
      // B
      const octets = prt.transferSize

      // Convert to b
      const bits = octets * 8

      // ms
      let duration = prt.duration

      if (duration <= 0) {
        continue
      }

      // Convert to s
      duration /= 1000

      // b/s
      let networkSpeed = bits / duration

      // Convert to kb/s
      networkSpeed /= 1000

      networkSpeedLookup[prt.name] = networkSpeed

      const averageNetworkSpeed: number = Math.round(
        Object.values(networkSpeedLookup).reduce((total, s) => total + s, 0) /
          Object.values(networkSpeedLookup).length,
      )

      await setNetwork(averageNetworkSpeed)

      // Only log every 10th measurement to avoid flooding the console
      if (Object.values(networkSpeedLookup).length % 10 === 0) {
        log(`${averageNetworkSpeed} kb/s`)
      }
    }

    const navigationTiming: PerformanceNavigationTiming | undefined = list
      .getEntries()
      .find((e) => e.entryType === 'navigation') as
      | PerformanceNavigationTiming
      | undefined

    if (navigationTiming) {
      // ms
      let pageLoad = navigationTiming.duration

      // Convert to s
      pageLoad /= 1000

      // Round to 2 decimal places
      pageLoad = parseFloat(pageLoad.toFixed(2))

      await setPageLoad(pageLoad)

      log(`Page load: ${pageLoad}s`)
    }
  }

export async function measureSpeed(): Promise<void> {
  if (instantiated) {
    return
  }

  await unsetSpeed()

  new PerformanceObserver(handlePerformanceObservations).observe({
    entryTypes: ['navigation', 'resource'],
  })

  instantiated = true
}
