import React from 'react'

import { ApolloProvider } from '@apollo/client'

import { client } from '../../apollo'
import MaterialUiProvider from '../MaterialUiProvider'
import Enterprise from './Enterprise'

export default function EnterprisePage({
  cspNonce,
  enterprisePlanId,
}: {
  cspNonce: string
  enterprisePlanId: string | null
}) {
  return (
    <ApolloProvider client={client}>
      <MaterialUiProvider cspNonce={cspNonce}>
        <Enterprise enterprisePlanId={enterprisePlanId} />
      </MaterialUiProvider>
    </ApolloProvider>
  )
}
