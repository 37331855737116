import {
  FBRecommendationKey,
  RecommendationCardType,
  RecommendationTaskType,
} from '../../utils/enums'
import metadata from '../metadata/facebook.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import specs from '../specs/facebook.yaml'

const FB_RECOMMENDATION_KEYS = [
  FBRecommendationKey.FB_ABOUT_SETTINGS,
  FBRecommendationKey.FB_VISIBILITY,
  FBRecommendationKey.FB_WHO_CAN_CONNECT,
  FBRecommendationKey.FB_WHAT_YOURE_TAGGED_IN,
  FBRecommendationKey.FB_CONNECTED_APPS,
  FBRecommendationKey.FB_PROFILE_PHOTO_PRIVACY,
  FBRecommendationKey.FB_COVER_PHOTO_PRIVACY,
  FBRecommendationKey.FB_CURRENT_COVER_PHOTO,
  FBRecommendationKey.FB_ALBUM_PRIVACY_V2,
  FBRecommendationKey.FB_PHOTO_TAGS_V2,
  FBRecommendationKey.FB_SETUP_2FA,
  FBRecommendationKey.FB_CHANGE_NAME,
  FBRecommendationKey.FB_PUBLIC_VIEW,
  FBRecommendationKey.FB_WHERE_LOGGED_IN,
  FBRecommendationKey.FB_RECOGNIZED_DEVICES,
  FBRecommendationKey.FB_OFF_PLATFORM_ACTIVITY,
  FBRecommendationKey.FB_LOGIN_ALERTS,
  FBRecommendationKey.FB_DISCOVERABILITY,
  FBRecommendationKey.FB_SEARCH_ENGINE,
  FBRecommendationKey.FB_MESSAGE_REQUESTS,
  FBRecommendationKey.FB_FOLLOWERS_PUBLIC_CONTENT,
  FBRecommendationKey.FB_VIEWING_SHARING,
  FBRecommendationKey.FB_USER_ACTIVITY_NOTIFICATIONS,
  FBRecommendationKey.FB_ENGAGEMENT_NOTIFICATIONS,
  FBRecommendationKey.FB_UNFRIEND,
]

const FB_SETUP_EXCLUSION_KEYS = [
  FBRecommendationKey.FB_PROFILE_PHOTO_PRIVACY,
  FBRecommendationKey.FB_COVER_PHOTO_PRIVACY,
  FBRecommendationKey.FB_ALBUM_PRIVACY_V2,
  FBRecommendationKey.FB_PHOTO_TAGS_V2,
  FBRecommendationKey.FB_UNFRIEND,
  FBRecommendationKey.FB_SETUP_2FA,
]

const FB_RECOMMENDATIONS_REGISTRY: Record<FBRecommendationKey, Recommendation> =
  {
    [FBRecommendationKey.FB_ABOUT_SETTINGS]: {
      ...parseSpec(specs[FBRecommendationKey.FB_ABOUT_SETTINGS]),
      key: FBRecommendationKey.FB_ABOUT_SETTINGS,
    },
    [FBRecommendationKey.FB_VISIBILITY]: {
      ...parseSpec(specs[FBRecommendationKey.FB_VISIBILITY]),
      key: FBRecommendationKey.FB_VISIBILITY,
    },
    [FBRecommendationKey.FB_WHO_CAN_CONNECT]: {
      ...parseSpec(specs[FBRecommendationKey.FB_WHO_CAN_CONNECT]),
      key: FBRecommendationKey.FB_WHO_CAN_CONNECT,
    },
    [FBRecommendationKey.FB_WHAT_YOURE_TAGGED_IN]: {
      ...parseSpec(specs[FBRecommendationKey.FB_WHAT_YOURE_TAGGED_IN]),
      key: FBRecommendationKey.FB_WHAT_YOURE_TAGGED_IN,
    },
    [FBRecommendationKey.FB_CONNECTED_APPS]: {
      ...parseSpec(specs[FBRecommendationKey.FB_CONNECTED_APPS]),
      isUserActivityDependent: true,
      key: FBRecommendationKey.FB_CONNECTED_APPS,
    },
    [FBRecommendationKey.FB_PROFILE_PHOTO_PRIVACY]: {
      ...parseSpec(specs[FBRecommendationKey.FB_PROFILE_PHOTO_PRIVACY]),
      isPhoto: true,
      isUserActivityDependent: true,
      key: FBRecommendationKey.FB_PROFILE_PHOTO_PRIVACY,
    },
    [FBRecommendationKey.FB_COVER_PHOTO_PRIVACY]: {
      ...parseSpec(specs[FBRecommendationKey.FB_COVER_PHOTO_PRIVACY]),
      isPhoto: true,
      isUserActivityDependent: true,
      key: FBRecommendationKey.FB_COVER_PHOTO_PRIVACY,
    },
    [FBRecommendationKey.FB_CURRENT_COVER_PHOTO]: {
      ...parseSpec(specs[FBRecommendationKey.FB_CURRENT_COVER_PHOTO]),
      isPhoto: true,
      key: FBRecommendationKey.FB_CURRENT_COVER_PHOTO,
    },
    [FBRecommendationKey.FB_ALBUM_PRIVACY_V2]: {
      ...parseSpec(specs[FBRecommendationKey.FB_ALBUM_PRIVACY_V2]),
      isPhoto: true,
      isUserActivityDependent: true,
      key: FBRecommendationKey.FB_ALBUM_PRIVACY_V2,
    },
    [FBRecommendationKey.FB_PHOTO_TAGS_V2]: {
      ...parseSpec(specs[FBRecommendationKey.FB_PHOTO_TAGS_V2]),
      recommendationCardType: RecommendationCardType.FACEBOOK_PHOTO_UNTAGGING,
      isPhoto: true,
      isUserActivityDependent: true,
      key: FBRecommendationKey.FB_PHOTO_TAGS_V2,
    },
    [FBRecommendationKey.FB_SETUP_2FA]: {
      ...parseSpec(specs[FBRecommendationKey.FB_SETUP_2FA]),
      hasAutomatedGather: true,
      key: FBRecommendationKey.FB_SETUP_2FA,
    },
    [FBRecommendationKey.FB_CHANGE_NAME]: {
      ...parseSpec(specs[FBRecommendationKey.FB_CHANGE_NAME]),
      key: FBRecommendationKey.FB_CHANGE_NAME,
    },
    [FBRecommendationKey.FB_PUBLIC_VIEW]: {
      ...parseSpec(specs[FBRecommendationKey.FB_PUBLIC_VIEW]),
      key: FBRecommendationKey.FB_PUBLIC_VIEW,
    },
    [FBRecommendationKey.FB_WHERE_LOGGED_IN]: {
      ...parseSpec(specs[FBRecommendationKey.FB_WHERE_LOGGED_IN]),
      key: FBRecommendationKey.FB_WHERE_LOGGED_IN,
    },
    [FBRecommendationKey.FB_RECOGNIZED_DEVICES]: {
      ...parseSpec(specs[FBRecommendationKey.FB_RECOGNIZED_DEVICES]),
      key: FBRecommendationKey.FB_RECOGNIZED_DEVICES,
    },
    [FBRecommendationKey.FB_OFF_PLATFORM_ACTIVITY]: {
      ...parseSpec(specs[FBRecommendationKey.FB_OFF_PLATFORM_ACTIVITY]),
      key: FBRecommendationKey.FB_OFF_PLATFORM_ACTIVITY,
    },
    [FBRecommendationKey.FB_LOGIN_ALERTS]: {
      ...parseSpec(specs[FBRecommendationKey.FB_LOGIN_ALERTS]),
      key: FBRecommendationKey.FB_LOGIN_ALERTS,
    },
    [FBRecommendationKey.FB_DISCOVERABILITY]: {
      ...parseSpec(specs[FBRecommendationKey.FB_DISCOVERABILITY]),
      key: FBRecommendationKey.FB_DISCOVERABILITY,
    },
    [FBRecommendationKey.FB_SEARCH_ENGINE]: {
      ...parseSpec(specs[FBRecommendationKey.FB_SEARCH_ENGINE]),
      key: FBRecommendationKey.FB_SEARCH_ENGINE,
    },
    [FBRecommendationKey.FB_MESSAGE_REQUESTS]: {
      ...parseSpec(specs[FBRecommendationKey.FB_MESSAGE_REQUESTS]),
      key: FBRecommendationKey.FB_MESSAGE_REQUESTS,
    },
    [FBRecommendationKey.FB_FOLLOWERS_PUBLIC_CONTENT]: {
      ...parseSpec(specs[FBRecommendationKey.FB_FOLLOWERS_PUBLIC_CONTENT]),
      key: FBRecommendationKey.FB_FOLLOWERS_PUBLIC_CONTENT,
    },
    [FBRecommendationKey.FB_VIEWING_SHARING]: {
      ...parseSpec(specs[FBRecommendationKey.FB_VIEWING_SHARING]),
      key: FBRecommendationKey.FB_VIEWING_SHARING,
    },
    [FBRecommendationKey.FB_USER_ACTIVITY_NOTIFICATIONS]: {
      ...parseSpec(specs[FBRecommendationKey.FB_USER_ACTIVITY_NOTIFICATIONS]),
      key: FBRecommendationKey.FB_USER_ACTIVITY_NOTIFICATIONS,
    },
    [FBRecommendationKey.FB_ENGAGEMENT_NOTIFICATIONS]: {
      ...parseSpec(specs[FBRecommendationKey.FB_ENGAGEMENT_NOTIFICATIONS]),
      key: FBRecommendationKey.FB_ENGAGEMENT_NOTIFICATIONS,
    },
    [FBRecommendationKey.FB_UNFRIEND]: {
      ...parseSpec(specs[FBRecommendationKey.FB_UNFRIEND]),
      hasAutomatedGather: true,
      isAutomated: false,
      key: FBRecommendationKey.FB_UNFRIEND,
    },
  }

export const facebookRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => FB_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () =>
    FB_RECOMMENDATION_KEYS.filter(
      (key) => !FB_SETUP_EXCLUSION_KEYS.includes(key),
    ),
  getChecklistKeys: () =>
    FB_RECOMMENDATION_KEYS.filter(
      (key) => !FB_RECOMMENDATIONS_REGISTRY[key].taskType,
    ),
  getContentTaskKeys: () =>
    FB_RECOMMENDATION_KEYS.filter(
      (key) =>
        FB_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.CONTENT,
    ),
  getExperienceTaskKeys: () =>
    FB_RECOMMENDATION_KEYS.filter(
      (key) =>
        FB_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.EXPERIENCE,
    ),
  getRecommendationsRegistry: () => FB_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
