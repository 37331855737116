/* eslint-disable max-len */
import type { ErrorEvent } from '@sentry/browser'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'

export function redact(incomingS: string): string {
  if (!isString(incomingS)) {
    return incomingS
  }

  let s = incomingS

  // [20241125 Sentry]
  // //a[contains(@href, "?fbid=12345678901")]
  s = s.replace(
    /\/\/a\[contains\(@href, "\?fbid=\d+"\)\]/g,
    '//a[contains(@href, "?fbid=[album id]")]',
  )

  // [20241125 Remote logs, Sentry]
  // //a[@href="https://www.facebook.com/some.user.9"][@role="link"]
  s = s.replace(
    /\/\/a\[@href="https:\/\/www\.facebook\.com\/[a-zA-Z0-9\\.]+"\]\[@role="link"\]/g,
    '//a[@href="https://www.facebook.com/[username]"][@role="link"]',
  )

  // [20241125 Remote logs, Sentry]
  // https://www.facebook.com/profile.php?id=123456789012345
  s = s.replace(
    /https:\/\/www\.facebook\.com\/profile.php\?id=\d+/g,
    'https://www.facebook.com/profile.php?id=[profile id]',
  )

  // [20241125 Remote logs]
  // a.12345678901234567
  s = s.replace(/a\.\d+/g, 'a.[album id]')

  // [20241125 Remote logs]
  // //*[local-name()="svg"][@aria-label="Firstname Lastname"][@role="img"]
  s = s.replace(
    /\/\/\*\[local-name\(\)="svg"\]\[@aria-label=".+"\]\[@role="img"\]/g,
    '//*[local-name()="svg"][@aria-label="[full name]"][@role="img"]',
  )

  // [20241125 Sentry]
  // https://accountscenter.facebook.com/profiles/123456789012345
  // https://accountscenter.facebook.com/profiles/123456789012345/
  // https://accountscenter.facebook.com/profiles/123456789012345/name
  // https://accountscenter.facebook.com/profiles/123456789012345/username
  s = s.replace(
    /https:\/\/accountscenter\.facebook\.com\/profiles\/\d+(\/?name|username)?/g,
    'https://accountscenter.facebook.com/profiles/[profile id]$1',
  )

  // [20241125 Sentry]
  // Account row with username someusername not found
  s = s.replace(
    /Account row with username .+ not found/g,
    'Account row with username [username] not found',
  )

  return s
}

export function redactSentryEvent(incomingEvent: ErrorEvent): ErrorEvent {
  if (!isObject(incomingEvent) || isArray(incomingEvent)) {
    return incomingEvent
  }

  try {
    const event = { ...incomingEvent }

    if (event.breadcrumbs) {
      event.breadcrumbs = event.breadcrumbs.map((b) => ({
        ...b,
        message: redact(b.message),
      }))
    }

    if (event.exception?.values) {
      event.exception.values = event.exception.values.map((e) => ({
        ...e,
        value: redact(e.value),
      }))
    }

    if (event.message) {
      event.message = redact(event.message)
    }

    if (event.request?.headers) {
      const redactedHeaders = {}
      for (const [key, value] of Object.entries(event.request.headers)) {
        redactedHeaders[key] = redact(value)
      }
      event.request.headers = redactedHeaders
    }

    return event
    // eslint-disable-next-line no-empty
  } catch {}

  return incomingEvent
}
