import React, { FormEvent, useCallback, useState } from 'react'

import styles from './NotificationPreview.module.scss'

export default function NotificationPreview() {
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')

  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(event.target.value)
    },
    [],
  )

  const handleBodyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBody(event.target.value)
    },
    [],
  )

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      // Request notification permission if not already granted
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission()
      }

      // Send notification if permission is granted
      if (Notification.permission === 'granted') {
        /* eslint-disable no-new */
        new Notification(title, {
          body,
          icon: '/static/src/icons/block-party-logo.png',
        })

        // Clear form
        setTitle('')
        setBody('')
      }
    },
    [title, body],
  )

  return (
    <div className="notification-preview">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="notificationTitle">
            Title:
            <input
              className={styles.inputField}
              type="text"
              id="notificationTitle"
              value={title}
              onChange={handleTitleChange}
              required
            />
          </label>
        </div>
        <div>
          <label htmlFor="notificationBody">
            Body:
            <input
              className={styles.inputField}
              type="text"
              id="notificationBody"
              value={body}
              onChange={handleBodyChange}
              required
            />
          </label>
        </div>
        <br />
        <div>
          <h3>Notes:</h3>
          <ul>
            <li>
              Notification permissions must be ON for admin site AND in your
              computer settings for this to work.
            </li>
            <li>
              The generated notification will have the admin site as a subtitle.
              Extension notifications will <strong>not</strong> have this line.
            </li>
          </ul>
        </div>
        <button type="submit">Test Notification</button>
      </form>
    </div>
  )
}
