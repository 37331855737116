import React, { useCallback, useState } from 'react'

import { useQuery } from '@apollo/client'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Drawer as MuiDrawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  CircularProgress,
} from '@mui/material'
import { CSSObject, styled, Theme } from '@mui/material/styles'

import Item from './Item'
import {
  Direction,
  EnterprisePlansDocument,
} from '../../../../__generated__/graphql'

const drawerWidth = 248

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
})

const StyledMuiDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}))

export default function Drawer() {
  const { data, loading } = useQuery(EnterprisePlansDocument, {
    variables: {
      input: {
        direction: Direction.Asc,
        perPage: 50,
        orderBy: 'id',
        page: 0,
        search: '',
      },
    },
  })

  const [open, setOpen] = useState<boolean>(false)

  const handleToggleClick = useCallback(() => {
    setOpen((currentOpen) => !currentOpen)
  }, [])

  return (
    <StyledMuiDrawer
      variant="permanent"
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          pt: 8,
        },
      }}
    >
      <List>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={handleToggleClick}
            sx={{ minHeight: 56, pl: 3 }}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ListItemButton>
        </ListItem>
        <Divider />
        {loading && (
          <ListItem>
            <CircularProgress />
          </ListItem>
        )}
        {data &&
          data.enterprisePlans.items.map((ep) => (
            <Item enterprisePlan={ep} key={ep.id} open={open} />
          ))}
      </List>
    </StyledMuiDrawer>
  )
}
