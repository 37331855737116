import React, { MouseEvent, useCallback, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import {
  Chip,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'

import { formatMonthDayAndYear } from '$extensionSrc/utils/dateUtils'

import type { Mode } from './Enterprise'
import {
  Direction,
  EnterprisePlanSeatsDocument,
  Status,
} from '../../../__generated__/graphql'

export default function Seats({
  enterprisePlanId,
  mode,
}: {
  enterprisePlanId: string | null
  mode: Mode
}) {
  const [page, setPage] = useState<number>(1)

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement>, newPage: number) => {
      setPage(newPage)
    },
    [],
  )

  const { data, loading } = useQuery(EnterprisePlanSeatsDocument, {
    variables: {
      enterprisePlanInput: {
        id: enterprisePlanId,
      },
      seatsInput: {
        direction: mode === 'license' ? Direction.Desc : Direction.Asc,
        orderBy: mode === 'license' ? 'status' : 'last_used',
        perPage: 5,
        page: page - 1,
        search: '',
      },
    },
  })

  const count: number = useMemo(
    () => (!data ? 0 : Math.ceil(data.enterprisePlan.seats.count / 5)),
    [data],
  )

  const colSpan: number = useMemo(() => (mode === 'license' ? 5 : 3), [mode])

  return (
    <Table>
      <TableHead>
        {loading && (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <Skeleton />
            </TableCell>
          </TableRow>
        )}
        {data && (
          <TableRow>
            {mode === 'license' && (
              <>
                <TableCell>
                  <strong>License</strong>
                </TableCell>
                <TableCell>
                  <strong>Status</strong>
                </TableCell>
              </>
            )}
            <TableCell>
              <strong>Email address</strong>
            </TableCell>
            <TableCell>
              <strong>Activated</strong>
            </TableCell>
            <TableCell>
              <strong>Last used</strong>
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      {data && (
        <>
          <TableBody>
            {data.enterprisePlan.seats.items.length === 0 && (
              <TableRow>
                <TableCell colSpan={colSpan}>No seats found</TableCell>
              </TableRow>
            )}
            {data.enterprisePlan.seats.items.map((s) => (
              <TableRow key={s.id}>
                {mode === 'license' && (
                  <>
                    <TableCell>{s.license}</TableCell>
                    <TableCell>
                      {s.status === Status.Available && (
                        <Chip label="Available" size="small" />
                      )}
                      {s.status === Status.InUse && (
                        <Chip color="success" label="In use" size="small" />
                      )}
                    </TableCell>
                  </>
                )}
                <TableCell>{s.email || '-'}</TableCell>
                <TableCell>
                  {s.activated ? formatMonthDayAndYear(s.activated) : '-'}
                </TableCell>
                <TableCell>
                  {s.lastUsed ? formatMonthDayAndYear(s.lastUsed) : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {count > 1 && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Pagination
                    count={count}
                    onChange={handlePageChange}
                    page={page}
                    sx={{
                      '& > .MuiPagination-ul': {
                        justifyContent: 'center',
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </>
      )}
    </Table>
  )
}
