import React from 'react'

import {
  Card,
  CardContent,
  Grid2 as Grid,
  Skeleton,
  Typography,
} from '@mui/material'

export default function UsageItem({
  label,
  loading,
  value,
}: {
  label: string
  loading: boolean
  value: string
}) {
  return (
    <Grid size={6}>
      <Card elevation={2}>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Typography component="div" variant="h5">
              <strong>{label}</strong>
            </Typography>
            {loading && <Skeleton sx={{ minWidth: 64 }} />}
            {!loading && (
              <Typography component="div" variant="h5">
                {value}
              </Typography>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
