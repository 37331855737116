import { RecommendationSpec } from './recommendationTypes'
import { ConcernTag, RecommendationCardType } from '../utils/enums'

function parseConcernTag(text: string): ConcernTag {
  if (text in ConcernTag) {
    return ConcernTag[text]
  }
  throw new Error(`Invalid concern tag: ${text}`)
}

export function parseSpec(rawRecommendationSpecFromYaml): RecommendationSpec {
  if (!rawRecommendationSpecFromYaml) {
    throw new Error('Failed to get recommendation spec from yaml')
  }

  let recommendationCardType: RecommendationCardType =
    RecommendationCardType.MANUAL
  if (rawRecommendationSpecFromYaml.isAutomated) {
    recommendationCardType = RecommendationCardType.AUTOMATED
  } else if (rawRecommendationSpecFromYaml.isTask) {
    recommendationCardType = RecommendationCardType.TASK
  }
  const parsedSpec = {
    ...rawRecommendationSpecFromYaml,
    steps: (rawRecommendationSpecFromYaml.steps || []).map(
      (stepRawMarkdownText, i) => ({
        id: i,
        description: stepRawMarkdownText,
      }),
    ),
    recommendationCardType,
  }

  // Handle null values
  if (parsedSpec.concernTags === null || parsedSpec.concernTags.length === 0) {
    throw new Error('Recommendation spec must have at least one concern tag')
  }
  if (parsedSpec.settings === null) {
    parsedSpec.settings = []
  }

  // Parse concern tags
  parsedSpec.concernTags = parsedSpec.concernTags.map(parseConcernTag)

  // Parse explanation text
  const parsedExplanationText: {
    explanationTextOld: string | null
    explanationTextByConcernTag: { [key in ConcernTag]?: string } | null
  } = { explanationTextOld: null, explanationTextByConcernTag: null }
  if (typeof parsedSpec.explanationText === 'string') {
    parsedExplanationText.explanationTextOld = parsedSpec.explanationText
  } else if (typeof parsedSpec.explanationText === 'object') {
    const parsedMap = {}
    for (const [k, v] of Object.entries(parsedSpec.explanationText)) {
      parsedMap[parseConcernTag(k)] = v
    }
    parsedExplanationText.explanationTextByConcernTag = parsedMap
  } else {
    throw new Error(
      `Invalid explanation text type; found {parsedSpec.explanationText}`,
    )
  }

  // Ensure consistency of concern tags & explanation text
  if (parsedExplanationText.explanationTextByConcernTag) {
    const concernTagsThatNeedExplanationText: ConcernTag[] = []
    const explanationTextNotInConcernTags: string[] = []

    for (const concernTag of parsedSpec.concernTags) {
      if (!parsedExplanationText.explanationTextByConcernTag[concernTag]) {
        concernTagsThatNeedExplanationText.push(concernTag)
      }
    }

    for (const concernTag in parsedExplanationText.explanationTextByConcernTag) {
      if (!parsedSpec.concernTags.includes(concernTag)) {
        explanationTextNotInConcernTags.push(concernTag)
      }
    }

    if (
      concernTagsThatNeedExplanationText.length > 0 ||
      explanationTextNotInConcernTags.length > 0
    ) {
      throw new Error(
        `Mismatch in keys: ${concernTagsThatNeedExplanationText} need ` +
          `explanation text, and ${explanationTextNotInConcernTags} are not ` +
          `in concern tags.`,
      )
    }
  }

  if (rawRecommendationSpecFromYaml.isAutomated) {
    if (parsedSpec.settings.length < 1) {
      throw new Error(`Automated recommendation spec must have settings`)
    }
  }

  if (
    rawRecommendationSpecFromYaml.isAutomated &&
    rawRecommendationSpecFromYaml.isTask
  ) {
    throw new Error('Recommendation spec cannot be both automated and a task')
  }

  let settingsMap = []
  if (parsedSpec.settings) {
    settingsMap = Object.entries(parsedSpec.settings).map(
      ([key, settingDesc]) => ({
        description: settingDesc,
        key,
      }),
    )
  }

  return {
    ...parsedSpec,
    ...parsedExplanationText,
    settings: settingsMap,
  }
}
