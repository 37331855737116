export function getBulkRequestUrlForKey(
  seriesNames: string[],
  options: { startDateAsStr?: string; endDateAsStr?: string },
) {
  const params = new URLSearchParams({
    seriesNames: seriesNames.join(','),
    startDate: options.startDateAsStr,
    endDate: options.endDateAsStr,
  })

  return `/api/bulk-query-timeseries-data/?${params.toString()}`
}
