import {
  Recommendation,
  RecommendationKeyEnum,
  RecommendationKeyEnumArray,
  RecommendationMetadataSpec,
  RecommendationRegistry,
} from './recommendationTypes'
import { facebookRegistry } from './registries/fbRegistry'
import { googleRegistry } from './registries/googleRegistry'
import { instagramRegistry } from './registries/instagramRegistry'
import { linkedinRegistry } from './registries/linkedinRegistry'
import { redditRegistry } from './registries/redditRegistry'
import { snapchatRegistry } from './registries/snapchatRegistry'
import { stravaRegistry } from './registries/stravaRegistry'
import { tiktokRegistry } from './registries/tiktokRegistry'
import { twitterRegistry } from './registries/twitterRegistry'
import { venmoRegistry } from './registries/venmoRegistry'
import { youtubeRegistry } from './registries/youtubeRegistry'
import { RecommendationRegistryApi } from './registryTypes'
import { getInstagramAccountTypeFilter } from '../automations/instagram/recommendationFiltering'
import BuildConfig from '../buildConfig'
import { InstagramRecommendationKey, PLATFORM } from '../utils/enums'

function getRecommendationRegistryApiForPlatform(
  platform: string | null,
): RecommendationRegistryApi | null {
  switch (platform) {
    case PLATFORM.FACEBOOK:
      return facebookRegistry
    case PLATFORM.GOOGLE:
      return googleRegistry
    case PLATFORM.INSTAGRAM:
      return instagramRegistry
    case PLATFORM.LINKEDIN:
      return linkedinRegistry
    case PLATFORM.REDDIT:
      return redditRegistry
    case PLATFORM.SNAPCHAT:
      return snapchatRegistry
    case PLATFORM.STRAVA:
      return stravaRegistry
    case PLATFORM.TIKTOK:
      return tiktokRegistry
    case PLATFORM.TWITTER:
      return twitterRegistry
    case PLATFORM.VENMO:
      return venmoRegistry
    case PLATFORM.YOUTUBE:
      return youtubeRegistry
    default:
      return null
  }
}

export function getRecommendationKeysForPlatform(
  platform: string,
): RecommendationKeyEnumArray {
  const registryInterface = getRecommendationRegistryApiForPlatform(platform)

  if (!registryInterface) {
    return []
  }

  return registryInterface.getRecommendationKeys()
}

export function getChecklistKeysForPlatform(
  platform: string,
): RecommendationKeyEnumArray {
  const registryInterface = getRecommendationRegistryApiForPlatform(platform)

  if (!registryInterface) {
    return []
  }

  return registryInterface.getChecklistKeys()
}

export function getRecommendationsRegistryForPlatform(
  platform: string | null,
): RecommendationRegistry {
  const registryInterface = getRecommendationRegistryApiForPlatform(platform)

  if (!registryInterface) {
    return {}
  }

  return registryInterface.getRecommendationsRegistry()
}

/**
 * Given a set of recommendation keys for a particular platform, filter out
 * keys we don't want given the settings in the debug panel, and any platform
 * specific rules.
 */
function filterKeysToUse(
  keysToUse: RecommendationKeyEnumArray,
  platform: string,
  accountData,
  debugData,
): RecommendationKeyEnumArray {
  let updatedKeysToUse = keysToUse

  // If not in production, apply overrides from debug panel
  if (BuildConfig.REACT_APP_ENV !== 'production') {
    const keysToUseOverrideMap =
      debugData?.RECOMMENDATION_KEYS_TO_USE_OVERRIDE?.[platform]

    if (keysToUseOverrideMap) {
      updatedKeysToUse = updatedKeysToUse.filter(
        (key) =>
          key in keysToUseOverrideMap && keysToUseOverrideMap[key] === true,
      ) as RecommendationKeyEnumArray
    }
  }

  // Apply platform-specific filters
  const specs = getRecommendationsRegistryForPlatform(platform)
  switch (platform) {
    case PLATFORM.INSTAGRAM:
      updatedKeysToUse = updatedKeysToUse.filter(
        getInstagramAccountTypeFilter(specs, accountData),
      ) as InstagramRecommendationKey[]
      break
    default:
    // pass
  }

  return updatedKeysToUse
}

export function getRecommendationKeysToUseForPlatform(
  platform,
  accountData,
  debugData,
): RecommendationKeyEnumArray {
  return filterKeysToUse(
    getRecommendationKeysForPlatform(platform),
    platform,
    accountData,
    debugData,
  )
}

export function getChecklistKeysToUseForPlatform(
  platform,
  accountData,
  debugData,
): RecommendationKeyEnumArray {
  return filterKeysToUse(
    getChecklistKeysForPlatform(platform),
    platform,
    accountData,
    debugData,
  )
}

export function getSetupRecommendationsToUseForPlatform(
  platform: string,
  accountData,
  debugData,
): Recommendation[] {
  const registryInterface = getRecommendationRegistryApiForPlatform(platform)

  if (!registryInterface) {
    return []
  }

  const registry = registryInterface.getRecommendationsRegistry()
  const keysToUse = filterKeysToUse(
    registryInterface.getSetupRecommendationKeys(),
    platform,
    accountData,
    debugData,
  )

  return keysToUse.map((key) => registry[key])
}

export function getContentTasksForPlatform(platform: string): Recommendation[] {
  const registryInterface = getRecommendationRegistryApiForPlatform(platform)
  const registry = registryInterface.getRecommendationsRegistry()

  if (!registryInterface || !registry) {
    return []
  }

  return registryInterface.getContentTaskKeys().map((key) => registry[key])
}

export function getExperienceTasksForPlatform(
  platform: string,
): Recommendation[] {
  const registryInterface = getRecommendationRegistryApiForPlatform(platform)
  const registry = registryInterface.getRecommendationsRegistry()

  if (!registryInterface || !registry) {
    return []
  }

  return registryInterface.getExperienceTaskKeys().map((key) => registry[key])
}

export function getSpecForPlatformRecommendation(
  platform: string,
  recommendationKey: RecommendationKeyEnum,
): Recommendation {
  const registry = getRecommendationsRegistryForPlatform(platform)
  return registry[recommendationKey]
}

export function getRecommendationsMetadataRegistryForPlatform(
  platform: string,
): Record<string, RecommendationMetadataSpec> {
  const registryInterface = getRecommendationRegistryApiForPlatform(platform)

  if (!registryInterface) {
    return {}
  }

  return registryInterface.getRecommendationsMetadataRegistry()
}

export function getMetadataForPlatformRecommendation(
  platform: string,
  recommendationKey: RecommendationKeyEnum,
): RecommendationMetadataSpec {
  const registry = getRecommendationsMetadataRegistryForPlatform(platform)
  return registry[recommendationKey]
}

export type AllRecommendationSpecs = Partial<
  Record<PLATFORM, RecommendationRegistry>
>

export function getAllRecommendationSpecs(): AllRecommendationSpecs {
  const allRecommendationSpecs: AllRecommendationSpecs = {}
  for (const platform of Object.values(PLATFORM)) {
    const recommendationSpecs = getRecommendationsRegistryForPlatform(platform)
    allRecommendationSpecs[platform] = recommendationSpecs
  }
  return allRecommendationSpecs
}

export function getHasAutomationsByPlatform(platform: string) {
  const registryInterface = getRecommendationRegistryApiForPlatform(platform)
  const registry = registryInterface.getRecommendationsRegistry()

  return registryInterface
    .getSetupRecommendationKeys()
    .some(
      (key) => registry[key].isAutomated || registry[key].hasAutomatedGather,
    )
}

export function getNumPlatformsWithAutomations(): number {
  let total = 0
  for (const platform of Object.keys(PLATFORM)) {
    if (getHasAutomationsByPlatform(platform)) {
      total += 1
    }
  }
  return total
}
