import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import {
  Avatar,
  Box,
  CircularProgress,
  CssBaseline,
  Grid2 as Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { formatMonthDayAndYear } from '$extensionSrc/utils/dateUtils'

import Drawer from './Drawer'
import Seats from './Seats'
import UsageItem from './UsageItem'
import {
  Direction,
  EnterprisePlanDocument,
} from '../../../__generated__/graphql'
import Blobby from '../../../icons/blobby.svg'

export type Mode = 'license' | 'sso'

export default function Enterprise({
  enterprisePlanId,
}: {
  enterprisePlanId: string | null
}) {
  const theme = useTheme()

  const { data, loading } = useQuery(EnterprisePlanDocument, {
    variables: {
      enterprisePlanInput: {
        id: enterprisePlanId,
      },
      seatsInput: {
        direction: Direction.Asc,
        orderBy: 'id',
        perPage: 5,
        page: 0,
        search: '',
      },
    },
  })

  const mode: Mode | null = useMemo(() => {
    if (!data) {
      return null
    }
    if (data.enterprisePlan.seats.available !== null) {
      return 'license'
    }
    return 'sso'
  }, [data])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer />
      <Box
        component="main"
        // 63px is height of admin header in Flask template
        sx={{ flexGrow: 1, minHeight: 'calc(100vh - 63px)', p: 3 }}
      >
        {!enterprisePlanId && (
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            sx={{ height: '100%' }}
          >
            <Grid sx={{ height: 371, width: 416 }}>
              <Blobby />
            </Grid>
          </Grid>
        )}
        {enterprisePlanId && (
          <Grid container direction="column" gap={4}>
            <Grid>
              <Grid alignItems="center" container gap={1.5}>
                <Grid>
                  <Avatar
                    sx={{
                      backgroundColor: theme.palette.primary.light,
                      height: 80,
                      width: 80,
                    }}
                  >
                    {loading && <CircularProgress />}
                    {data && data.enterprisePlan.organizationName.charAt(0)}
                  </Avatar>
                </Grid>
                <Grid>
                  {loading && <Skeleton sx={{ minWidth: 200 }} />}
                  {data && (
                    <Typography component="div" variant="h4">
                      <strong>{data.enterprisePlan.organizationName}</strong>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Paper elevation={3}>
                <Typography
                  component="div"
                  px={2}
                  py={1}
                  sx={{ fontWeight: 700, textTransform: 'uppercase' }}
                  variant="subtitle1"
                >
                  Engagement
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      {loading && (
                        <TableCell colSpan={2}>
                          <Skeleton />
                        </TableCell>
                      )}
                      {data?.enterprisePlan.startDate && (
                        <>
                          <TableCell>Start date</TableCell>
                          <TableCell>
                            {formatMonthDayAndYear(
                              data.enterprisePlan.startDate,
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                    {data?.enterprisePlan.planExpirationDate && (
                      <TableRow>
                        <TableCell>End date</TableCell>
                        <TableCell>
                          {formatMonthDayAndYear(
                            data.enterprisePlan.planExpirationDate,
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid>
              <Paper elevation={3}>
                <Typography
                  component="div"
                  px={2}
                  py={1}
                  sx={{ fontWeight: 700, textTransform: 'uppercase' }}
                  variant="subtitle1"
                >
                  Adoption
                </Typography>
                <Grid
                  alignItems="center"
                  container
                  justifyContent="space-between"
                  px={2}
                  py={1}
                >
                  <Grid>
                    <Typography
                      component="div"
                      sx={{ fontWeight: 700 }}
                      variant="h5"
                    >
                      Seats
                    </Typography>
                  </Grid>
                  <Grid>
                    {loading && <Skeleton sx={{ minWidth: 250 }} />}
                    {data && (
                      <Typography component="div" variant="h5">
                        <span>
                          <strong>{data.enterprisePlan.seats.inUse}</strong> in
                          use
                        </span>
                        {mode === 'license' && (
                          <span>
                            {' '}
                            |{' '}
                            <strong>
                              {data.enterprisePlan.seats.available}
                            </strong>{' '}
                            available
                          </span>
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {enterprisePlanId && (
                  <Seats enterprisePlanId={enterprisePlanId} mode={mode} />
                )}
              </Paper>
            </Grid>
            <Grid>
              <Paper elevation={3}>
                <Typography
                  component="div"
                  px={2}
                  py={1}
                  sx={{ fontWeight: 700, textTransform: 'uppercase' }}
                  variant="subtitle1"
                >
                  Usage
                </Typography>
                <Grid container px={2} py={1} spacing={2}>
                  <UsageItem
                    label="Checklists used"
                    loading={loading}
                    value={data?.enterprisePlan.usage.checklistsUsed.toString()}
                  />
                  <UsageItem
                    label="Settings fixed"
                    loading={loading}
                    value={data?.enterprisePlan.usage.settingsFixed.toString()}
                  />
                  <UsageItem
                    label="Posts cleaned"
                    loading={loading}
                    value={data?.enterprisePlan.usage.postsCleaned.toString()}
                  />
                  <UsageItem
                    label="Connections cleaned"
                    loading={loading}
                    value={data?.enterprisePlan.usage.connectionsCleaned.toString()}
                  />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  )
}
