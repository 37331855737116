import {
  GoogleRecommendationKey,
  RecommendationTaskType,
} from '../../utils/enums'
import metadata from '../metadata/google.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import specs from '../specs/google.yaml'

const GOOGLE_RECOMMENDATION_KEYS: GoogleRecommendationKey[] = [
  GoogleRecommendationKey.GOOGLE_2FA,
  GoogleRecommendationKey.GOOGLE_ACTIVITY_CONTROLS,
  GoogleRecommendationKey.GOOGLE_CONNECTIONS,
  GoogleRecommendationKey.GOOGLE_INACTIVE_ACCOUNT_MANAGER,
  GoogleRecommendationKey.GOOGLE_LOCATION_SHARING,
  GoogleRecommendationKey.GOOGLE_PASSWORD,
  GoogleRecommendationKey.GOOGLE_PAYMENTS,
  GoogleRecommendationKey.GOOGLE_PROFILE_INFO,
  GoogleRecommendationKey.GOOGLE_RECOVERY_PHONE_EMAIL,
  GoogleRecommendationKey.GOOGLE_SESSIONS,
  GoogleRecommendationKey.GOOGLE_SHARED_ENDORSEMENTS,
  GoogleRecommendationKey.GOOGLE_REVIEW_MAPS_CONTRIBUTIONS,
  GoogleRecommendationKey.GOOGLE_MAPS_PROFILE_PRIVACY,
  GoogleRecommendationKey.GOOGLE_RESULTS_ABOUT_YOU,
]

const GOOGLE_RECOMMENDATIONS_REGISTRY: Record<
  GoogleRecommendationKey,
  Recommendation
> = {
  [GoogleRecommendationKey.GOOGLE_2FA]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_2FA]),
    key: GoogleRecommendationKey.GOOGLE_2FA,
  },
  [GoogleRecommendationKey.GOOGLE_ACTIVITY_CONTROLS]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_ACTIVITY_CONTROLS]),
    key: GoogleRecommendationKey.GOOGLE_ACTIVITY_CONTROLS,
  },
  [GoogleRecommendationKey.GOOGLE_CONNECTIONS]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_CONNECTIONS]),
    key: GoogleRecommendationKey.GOOGLE_CONNECTIONS,
  },
  [GoogleRecommendationKey.GOOGLE_INACTIVE_ACCOUNT_MANAGER]: {
    ...parseSpec(
      specs[GoogleRecommendationKey.GOOGLE_INACTIVE_ACCOUNT_MANAGER],
    ),
    key: GoogleRecommendationKey.GOOGLE_INACTIVE_ACCOUNT_MANAGER,
  },
  [GoogleRecommendationKey.GOOGLE_LOCATION_SHARING]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_LOCATION_SHARING]),
    key: GoogleRecommendationKey.GOOGLE_LOCATION_SHARING,
  },
  [GoogleRecommendationKey.GOOGLE_PASSWORD]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_PASSWORD]),
    key: GoogleRecommendationKey.GOOGLE_PASSWORD,
  },
  [GoogleRecommendationKey.GOOGLE_PAYMENTS]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_PAYMENTS]),
    key: GoogleRecommendationKey.GOOGLE_PAYMENTS,
  },
  [GoogleRecommendationKey.GOOGLE_PROFILE_INFO]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_PROFILE_INFO]),
    key: GoogleRecommendationKey.GOOGLE_PROFILE_INFO,
  },
  [GoogleRecommendationKey.GOOGLE_RECOVERY_PHONE_EMAIL]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_RECOVERY_PHONE_EMAIL]),
    key: GoogleRecommendationKey.GOOGLE_RECOVERY_PHONE_EMAIL,
  },
  [GoogleRecommendationKey.GOOGLE_SESSIONS]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_SESSIONS]),
    key: GoogleRecommendationKey.GOOGLE_SESSIONS,
  },
  [GoogleRecommendationKey.GOOGLE_SHARED_ENDORSEMENTS]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_SHARED_ENDORSEMENTS]),
    key: GoogleRecommendationKey.GOOGLE_SHARED_ENDORSEMENTS,
  },
  [GoogleRecommendationKey.GOOGLE_REVIEW_MAPS_CONTRIBUTIONS]: {
    ...parseSpec(
      specs[GoogleRecommendationKey.GOOGLE_REVIEW_MAPS_CONTRIBUTIONS],
    ),
    key: GoogleRecommendationKey.GOOGLE_REVIEW_MAPS_CONTRIBUTIONS,
  },
  [GoogleRecommendationKey.GOOGLE_MAPS_PROFILE_PRIVACY]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_MAPS_PROFILE_PRIVACY]),
    key: GoogleRecommendationKey.GOOGLE_MAPS_PROFILE_PRIVACY,
  },
  [GoogleRecommendationKey.GOOGLE_RESULTS_ABOUT_YOU]: {
    ...parseSpec(specs[GoogleRecommendationKey.GOOGLE_RESULTS_ABOUT_YOU]),
    key: GoogleRecommendationKey.GOOGLE_RESULTS_ABOUT_YOU,
  },
}

export const googleRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => GOOGLE_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () => GOOGLE_RECOMMENDATION_KEYS,
  getChecklistKeys: () =>
    GOOGLE_RECOMMENDATION_KEYS.filter(
      (key) => !GOOGLE_RECOMMENDATIONS_REGISTRY[key].taskType,
    ),
  getContentTaskKeys: () =>
    GOOGLE_RECOMMENDATION_KEYS.filter(
      (key) =>
        GOOGLE_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.CONTENT,
    ),
  getExperienceTaskKeys: () =>
    GOOGLE_RECOMMENDATION_KEYS.filter(
      (key) =>
        GOOGLE_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.EXPERIENCE,
    ),
  getRecommendationsRegistry: () => GOOGLE_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
