export enum PROGRESS_STAGE {
  IN_PROGRESS = 'IN_PROGRESS',
  READY_TO_START = 'READY_TO_START',
  COMPLETE = 'COMPLETE',
  RESCAN = 'RESCAN',
}

export function getProgressStageDisplayName(progressStage) {
  return {
    [PROGRESS_STAGE.IN_PROGRESS]: 'In review',
    [PROGRESS_STAGE.READY_TO_START]: 'Ready to start',
    [PROGRESS_STAGE.COMPLETE]: 'For future review',
  }[progressStage]
}

export enum PLATFORM {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  INSTAGRAM = 'INSTAGRAM',
  LINKEDIN = 'LINKEDIN',
  REDDIT = 'REDDIT',
  STRAVA = 'STRAVA',
  SNAPCHAT = 'SNAPCHAT',
  TWITTER = 'TWITTER',
  TIKTOK = 'TIKTOK',
  VENMO = 'VENMO',
  YOUTUBE = 'YOUTUBE',
}

export const isPlatform = (k: unknown): k is PLATFORM =>
  PLATFORM[k as keyof PLATFORM] !== undefined

export const FB_PRIVACY_LEVEL = {
  PUBLIC: 'PUBLIC',
  FRIENDS_OF_FRIENDS: 'FRIENDS_OF_FRIENDS',
  FRIENDS: 'FRIENDS',
  ONLY_ME: 'ONLY_ME',
  CUSTOM: 'CUSTOM',
}

export enum FBRecommendationKey {
  FB_ABOUT_SETTINGS = 'FB_ABOUT_SETTINGS',
  FB_VISIBILITY = 'FB_VISIBILITY',
  FB_WHO_CAN_CONNECT = 'FB_WHO_CAN_CONNECT',
  FB_CONNECTED_APPS = 'FB_CONNECTED_APPS',
  FB_CURRENT_COVER_PHOTO = 'FB_CURRENT_COVER_PHOTO',
  FB_ALBUM_PRIVACY_V2 = 'FB_ALBUM_PRIVACY_V2',
  FB_PROFILE_PHOTO_PRIVACY = 'FB_PROFILE_PHOTO_PRIVACY',
  FB_COVER_PHOTO_PRIVACY = 'FB_COVER_PHOTO_PRIVACY',
  FB_PHOTO_TAGS_V2 = 'FB_PHOTO_TAGS_V2',
  FB_CHANGE_NAME = 'FB_CHANGE_NAME',
  FB_WHAT_YOURE_TAGGED_IN = 'FB_WHAT_YOURE_TAGGED_IN',
  FB_SETUP_2FA = 'FB_SETUP_2FA',
  FB_PUBLIC_VIEW = 'FB_PUBLIC_VIEW',
  FB_WHERE_LOGGED_IN = 'FB_WHERE_LOGGED_IN',
  FB_RECOGNIZED_DEVICES = 'FB_RECOGNIZED_DEVICES',
  FB_OFF_PLATFORM_ACTIVITY = 'FB_OFF_PLATFORM_ACTIVITY',
  FB_LOGIN_ALERTS = 'FB_LOGIN_ALERTS',
  FB_DISCOVERABILITY = 'FB_DISCOVERABILITY',
  FB_SEARCH_ENGINE = 'FB_SEARCH_ENGINE',
  FB_MESSAGE_REQUESTS = 'FB_MESSAGE_REQUESTS',
  FB_FOLLOWERS_PUBLIC_CONTENT = 'FB_FOLLOWERS_PUBLIC_CONTENT',
  FB_VIEWING_SHARING = 'FB_VIEWING_SHARING',
  FB_USER_ACTIVITY_NOTIFICATIONS = 'FB_USER_ACTIVITY_NOTIFICATIONS',
  FB_ENGAGEMENT_NOTIFICATIONS = 'FB_ENGAGEMENT_NOTIFICATIONS',
  FB_UNFRIEND = 'FB_UNFRIEND',
}

export enum GoogleRecommendationKey {
  GOOGLE_2FA = 'GOOGLE_2FA',
  GOOGLE_ACTIVITY_CONTROLS = 'GOOGLE_ACTIVITY_CONTROLS',
  GOOGLE_CONNECTIONS = 'GOOGLE_CONNECTIONS',
  GOOGLE_INACTIVE_ACCOUNT_MANAGER = 'GOOGLE_INACTIVE_ACCOUNT_MANAGER',
  GOOGLE_LOCATION_SHARING = 'GOOGLE_LOCATION_SHARING',
  GOOGLE_PASSWORD = 'GOOGLE_PASSWORD',
  GOOGLE_PAYMENTS = 'GOOGLE_PAYMENTS',
  GOOGLE_PROFILE_INFO = 'GOOGLE_PROFILE_INFO',
  GOOGLE_RECOVERY_PHONE_EMAIL = 'GOOGLE_RECOVERY_PHONE_EMAIL',
  GOOGLE_SESSIONS = 'GOOGLE_SESSIONS',
  GOOGLE_SHARED_ENDORSEMENTS = 'GOOGLE_SHARED_ENDORSEMENTS',
  GOOGLE_REVIEW_MAPS_CONTRIBUTIONS = 'GOOGLE_REVIEW_MAPS_CONTRIBUTIONS',
  GOOGLE_MAPS_PROFILE_PRIVACY = 'GOOGLE_MAPS_PROFILE_PRIVACY',
  GOOGLE_RESULTS_ABOUT_YOU = 'GOOGLE_RESULTS_ABOUT_YOU',
}

export enum InstagramRecommendationKey {
  INSTAGRAM_ADVANCED_COMMENT_FILTERING = 'INSTAGRAM_ADVANCED_COMMENT_FILTERING',
  INSTAGRAM_ARCHIVE_POSTS = 'INSTAGRAM_ARCHIVE_POSTS',
  INSTAGRAM_DELETE_YOUR_POSTS = 'INSTAGRAM_DELETE_YOUR_POSTS',
  INSTAGRAM_EMAIL_NOTIFICATIONS_V2 = 'INSTAGRAM_EMAIL_NOTIFICATIONS_V2',
  INSTAGRAM_HIDE_COMMENTS = 'INSTAGRAM_HIDE_COMMENTS',
  INSTAGRAM_HIDE_MESSAGES = 'INSTAGRAM_HIDE_MESSAGES',
  INSTAGRAM_PRIVATE_ACCOUNT = 'INSTAGRAM_PRIVATE_ACCOUNT',
  INSTAGRAM_PUSH_NOTIFICATIONS = 'INSTAGRAM_PUSH_NOTIFICATIONS',
  INSTAGRAM_SETUP_2FA = 'INSTAGRAM_SETUP_2FA',
  INSTAGRAM_STATUS_VISIBILITY = 'INSTAGRAM_STATUS_VISIBILITY',
  INSTAGRAM_STORY_REPLIES = 'INSTAGRAM_STORY_REPLIES',
  INSTAGRAM_TAGGING = 'INSTAGRAM_TAGGING',
  INSTAGRAM_WHO_CAN_COMMENT = 'INSTAGRAM_WHO_CAN_COMMENT',
  INSTAGRAM_UNTAG_PHOTOS = 'INSTAGRAM_UNTAG_PHOTOS',
}

export enum StravaRecommendationKey {
  STRAVA_VISIBILITY = 'STRAVA_VISIBILITY',
  STRAVA_COMMUNITY_FEATURES = 'STRAVA_COMMUNITY_FEATURES',
  STRAVA_MENTIONS = 'STRAVA_MENTIONS',
  STRAVA_MAP_VISIBILITY = 'STRAVA_MAP_VISIBILITY',
  STRAVA_PAST_ACTIVITIES = 'STRAVA_PAST_ACTIVITIES',
  STRAVA_NOTIFICATIONS = 'STRAVA_NOTIFICATIONS',
  STRAVA_FOLLOWERS = 'STRAVA_FOLLOWERS',
  STRAVA_PASSWORD = 'STRAVA_PASSWORD',
}

export enum VenmoRecommendationKey {
  VENMO_AUTOMATIC_FRIENDING = 'VENMO_AUTOMATIC_FRIENDING',
  VENMO_PAYMENT_PRIVACY = 'VENMO_PAYMENT_PRIVACY',
  VENMO_FRIENDS_LIST = 'VENMO_FRIENDS_LIST',
  VENMO_REMEMBERED_DEVICES = 'VENMO_REMEMBERED_DEVICES',
  VENMO_NOTIFICATIONS = 'VENMO_NOTIFICATIONS',
}

export enum TwitterRecommendationKey {
  TWITTER_2FA = 'TWITTER_2FA',
  TWITTER_AI_OPT_OUT = 'TWITTER_AI_OPT_OUT',
  TWITTER_AUDIO_VIDEO_CALLS = 'TWITTER_AUDIO_VIDEO_CALLS',
  TWITTER_BLOCK_REPOSTERS = 'TWITTER_BLOCK_REPOSTERS',
  TWITTER_CONTACTS = 'TWITTER_CONTACTS',
  TWITTER_LEAVE_DIRECT_MESSAGES = 'TWITTER_LEAVE_DIRECT_MESSAGES',
  TWITTER_DELETE_POSTS_BEFORE = 'TWITTER_DELETE_POSTS_BEFORE',
  TWITTER_DISCOVERABILITY = 'TWITTER_DISCOVERABILITY',
  TWITTER_LOCATION_ACCESS = 'TWITTER_LOCATION_ACCESS',
  TWITTER_LOCATION_PROFILE = 'TWITTER_LOCATION_PROFILE',
  TWITTER_LOCATION_TWEETS = 'TWITTER_LOCATION_TWEETS',
  TWITTER_MUTED_NOTIFICATIONS = 'TWITTER_MUTED_NOTIFICATIONS',
  TWITTER_REMOVE_LIKES = 'TWITTER_REMOVE_LIKES',
  TWITTER_SECURITY = 'TWITTER_SECURITY',
  TWITTER_SESSIONS = 'TWITTER_SESSIONS',
}

export enum LinkedinRecommendationKey {
  LINKEDIN_LOCATION_VISIBILITY = 'LINKEDIN_LOCATION_VISIBILITY',
  LINKEDIN_EMAIL_VISIBILITY = 'LINKEDIN_EMAIL_VISIBILITY',
  LINKEDIN_CONNECTIONS_LIST = 'LINKEDIN_CONNECTIONS_LIST',
  LINKEDIN_REPRESENT_COMPANY = 'LINKEDIN_REPRESENT_COMPANY',
  LINKEDIN_PROFILE_DISCOVERABILITY = 'LINKEDIN_PROFILE_DISCOVERABILITY',
  LINKEDIN_STATUS_VISIBILITY = 'LINKEDIN_STATUS_VISIBILITY',
  LINKEDIN_2FA = 'LINKEDIN_2FA',
  LINKEDIN_NOTIFICATION_PREFERENCES = 'LINKEDIN_NOTIFICATION_PREFERENCES',
  LINKEDIN_AI_RESEARCH_OPT_OUT = 'LINKEDIN_AI_RESEARCH_OPT_OUT',
}

export enum RedditRecommendationKey {
  REDDIT_DISCOVERABILITY = 'REDDIT_DISCOVERABILITY',
  REDDIT_CONTENT_COMMUNITY_VISIBILITY = 'REDDIT_CONTENT_COMMUNITY_VISIBILITY',
  REDDIT_2FA = 'REDDIT_2FA',
  REDDIT_CHAT = 'REDDIT_CHAT',
  REDDIT_THIRD_PARTY_ACCESS = 'REDDIT_THIRD_PARTY_ACCESS',
  REDDIT_PERSONALIZED_ADS = 'REDDIT_PERSONALIZED_ADS',
  REDDIT_NOTIFICATIONS = 'REDDIT_NOTIFICATIONS',
  REDDIT_EMAIL_NOTIFICATIONS = 'REDDIT_EMAIL_NOTIFICATIONS',
  REDDIT_PUBLIC_VIEW = 'REDDIT_PUBLIC_VIEW',
}

export enum YoutubeRecommendationKey {
  YOUTUBE_PUSH_NOTIFICATIONS = 'YOUTUBE_PUSH_NOTIFICATIONS',
  YOUTUBE_EMAIL_NOTIFICATIONS = 'YOUTUBE_EMAIL_NOTIFICATIONS',
  YOUTUBE_SUBSCRIPTION_PRIVACY = 'YOUTUBE_SUBSCRIPTION_PRIVACY',
  YOUTUBE_CONNECTED_APPS = 'YOUTUBE_CONNECTED_APPS',
  YOUTUBE_PURCHASE = 'YOUTUBE_PURCHASE',
}

export enum TiktokRecommendationKey {
  TIKTOK_2FA_REMOVE_SAVED_LOGIN = 'TIKTOK_2FA_REMOVE_SAVED_LOGIN',
  TIKTOK_ACTIVITY_STATUS = 'TIKTOK_ACTIVITY_STATUS',
  TIKTOK_AD_DATA = 'TIKTOK_AD_DATA',
  TIKTOK_COMMENTS = 'TIKTOK_COMMENTS',
  TIKTOK_CONTENT_INTERACTIONS = 'TIKTOK_CONTENT_INTERACTIONS',
  TIKTOK_DISCOVERABILITY = 'TIKTOK_DISCOVERABILITY',
  TIKTOK_FAMILY_PAIRING = 'TIKTOK_FAMILY_PAIRING',
  TIKTOK_LIKED_VIDEOS = 'TIKTOK_LIKED_VIDEOS',
  TIKTOK_MANAGE_ADS = 'TIKTOK_MANAGE_ADS',
  TIKTOK_MESSAGES = 'TIKTOK_MESSAGES',
  TIKTOK_SCREEN_TIME = 'TIKTOK_SCREEN_TIME',
  TIKTOK_SUGGEST_ACCOUNT_TO_SYNCED_CONTACTS = 'TIKTOK_SUGGEST_ACCOUNT_TO_SYNCED_CONTACTS',
}

export enum SnapchatRecommendationKey {
  SNAPCHAT_ACTIVITY_INDICATOR = 'SNAPCHAT_ACTIVITY_INDICATOR',
  SNAPCHAT_AD_PREFERENCES = 'SNAPCHAT_AD_PREFERENCES',
  SNAPCHAT_CONTACT_ME = 'SNAPCHAT_CONTACT_ME',
  SNAPCHAT_FAMILY_CENTER = 'SNAPCHAT_FAMILY_CENTER',
  SNAPCHAT_LOCATION = 'SNAPCHAT_LOCATION',
  SNAPCHAT_NOTIFICATIONS = 'SNAPCHAT_NOTIFICATIONS',
  SNAPCHAT_QUICK_ADD = 'SNAPCHAT_QUICK_ADD',
  SNAPCHAT_SCREEN_TIME = 'SNAPCHAT_SCREEN_TIME',
  SNAPCHAT_TWO_FACTOR_AUTHENTICATION = 'SNAPCHAT_TWO_FACTOR_AUTHENTICATION',
}

export type RecommendationKey =
  | FBRecommendationKey
  | GoogleRecommendationKey
  | InstagramRecommendationKey
  | LinkedinRecommendationKey
  | RedditRecommendationKey
  | SnapchatRecommendationKey
  | StravaRecommendationKey
  | TwitterRecommendationKey
  | TiktokRecommendationKey
  | VenmoRecommendationKey
  | YoutubeRecommendationKey

export const isRecommendationKey = (k: unknown): k is RecommendationKey => {
  const keyEnums = [
    FBRecommendationKey,
    GoogleRecommendationKey,
    InstagramRecommendationKey,
    LinkedinRecommendationKey,
    RedditRecommendationKey,
    SnapchatRecommendationKey,
    StravaRecommendationKey,
    TwitterRecommendationKey,
    TiktokRecommendationKey,
    VenmoRecommendationKey,
    YoutubeRecommendationKey,
  ]
  for (const keyEnum of keyEnums) {
    if (keyEnum[k as keyof RecommendationKey] !== undefined) {
      return true
    }
  }
  return false
}

export enum RecommendationCardType {
  AUTOMATED = 'AUTOMATED',
  FACEBOOK_PHOTO_UNTAGGING = 'FACEBOOK_PHOTO_UNTAGGING',
  MANUAL = 'MANUAL',
  TASK = 'TASK',
}

export enum RecommendationTaskType {
  CONTENT = 'CONTENT',
  EXPERIENCE = 'EXPERIENCE',
}

export const isRecommendationCardType = (
  recommendationCardType: unknown,
): recommendationCardType is RecommendationCardType =>
  typeof recommendationCardType === 'string' &&
  RecommendationCardType[recommendationCardType] !== undefined

export enum RecommendationState {
  INCOMPLETE = 'INCOMPLETE',
  SKIPPED = 'SKIPPED',
  UPDATED = 'UPDATED',
  FULFILLED = 'FULFILLED',
}

export enum AutomationType {
  GATHER = 'GATHER',
  UPDATE = 'UPDATE',
  UNDO = 'UNDO',
}

export enum StageType {
  ACTION = 'ACTION',
  PAUSED = 'PAUSED',
  CANCEL = 'CANCEL',
  ERRORED = 'ERRORED',
  FINAL = 'FINAL',
  NEEDS_USER_INPUT = 'NEEDS_USER_INPUT',
}

export enum ControlStageKey {
  PAUSED = 'PAUSED',
  CANCEL = 'CANCEL',
  ERRORED = 'ERRORED',
  NEEDS_USER_INPUT = 'NEEDS_USER_INPUT',
  NEEDS_USER_LOGIN = 'NEEDS_USER_LOGIN',
  FINAL = 'FINAL',
}

export enum TriggeringEventType {
  ON_ERROR = 'onError',
  ON_EVENT_ACTION_COMPLETED = 'onEventActionCompleted',
  ON_INPUT_CANCEL = 'onInputCancel',
  ON_INPUT_PAUSE = 'onInputPause',
  ON_INPUT_RESUME = 'onInputResume',
  ON_INPUT_RETRY = 'onInputRetry',
  ON_NEEDS_USER_INPUT = 'onNeedsUserInput',
  ON_SKIP_ERROR = 'onSkipError',
}

export const MACHINE_STORAGE_KEY_SUFFIX = {
  SIGNAL: '_MACHINE_SIGNAL',
  CONTEXT: '_MACHINE_CONTEXT',
  INPUT_DATA: '_MACHINE_INPUT_DATA',
  RESUME_DATA: '_MACHINE_RESUME_DATA',
  PERSISTENT_DATA: '_MACHINE_PERSISTENT_DATA',
}

export const STORAGE_KEY = {
  ACCOUNT_DATA: 'ACCOUNT_DATA',
  BASIC_AUTH_CREDENTIALS: 'BASIC_AUTH_CREDENTIALS',
  CURRENT_USER: 'CURRENT_USER',
  DASHBOARD_PERMISSION_HEADER_CLOSED: 'DASHBOARD_PERMISSION_HEADER_CLOSED',
  DEBUG_DATA: 'DEBUG_DATA',
  DEEPLINK_RECOMMENDATION_KEYS: 'DEEPLINK_RECOMMENDATION_KEYS',
  DRY_RUN: 'DRY_RUN',
  EXTENSION_INSTALLATION_ID: 'EXTENSION_INSTALLATION_ID',
  FACEBOOK_ACTIVE_TAB_ID: 'FACEBOOK_ACTIVE_TAB_ID',
  FACEBOOK_MACHINE_CONTEXT: 'FACEBOOK_MACHINE_CONTEXT',
  FACEBOOK_MACHINE_KEY: 'FACEBOOK_MACHINE_KEY',
  FACEBOOK_MACHINE_OVERLAY_STATUS: 'FACEBOOK_MACHINE_OVERLAY_STATUS',
  FACEBOOK_MACHINE_SIGNAL: 'FACEBOOK_MACHINE_SIGNAL',
  FB_ABOUT_SETTINGS___URLS: 'FB_ABOUT_SETTINGS___URLS',
  FEATURE_FLAG: 'FEATURE_FLAG',
  FIRST_TIME_USER_MODAL_DISMISSED: 'FIRST_TIME_USER_MODAL_DISMISSED',
  GOOGLE_ACTIVE_TAB_ID: 'GOOGLE_ACTIVE_TAB_ID',
  GOOGLE_MACHINE_CONTEXT: 'GOOGLE_MACHINE_CONTEXT',
  GOOGLE_MACHINE_KEY: 'GOOGLE_MACHINE_KEY',
  GOOGLE_MACHINE_OVERLAY_STATUS: 'GOOGLE_MACHINE_OVERLAY_STATUS',
  GOOGLE_MACHINE_SIGNAL: 'GOOGLE_MACHINE_SIGNAL',
  HIDDEN_PLATFORMS: 'HIDDEN_PLATFORMS',
  INSTAGRAM_ACTIVE_TAB_ID: 'INSTAGRAM_ACTIVE_TAB_ID',
  INSTAGRAM_MACHINE_CONTEXT: 'INSTAGRAM_MACHINE_CONTEXT',
  INSTAGRAM_MACHINE_KEY: 'INSTAGRAM_MACHINE_KEY',
  INSTAGRAM_MACHINE_OVERLAY_STATUS: 'INSTAGRAM_MACHINE_OVERLAY_STATUS',
  INSTAGRAM_MACHINE_SIGNAL: 'INSTAGRAM_MACHINE_SIGNAL',
  LAST_EXTENSION_ERROR_STACK_TRACE: 'LAST_EXTENSION_ERROR_STACK_TRACE',
  LAST_FAILED_AUTOMATION_DATA: 'LAST_FAILED_AUTOMATION_DATA',
  LAST_SELECTED_ACCOUNT: 'LAST_SELECTED_ACCOUNT',
  LINKEDIN_ACTIVE_TAB_ID: 'LINKEDIN_ACTIVE_TAB_ID',
  LINKEDIN_MACHINE_CONTEXT: 'LINKEDIN_MACHINE_CONTEXT',
  LINKEDIN_MACHINE_KEY: 'LINKEDIN_MACHINE_KEY',
  LINKEDIN_MACHINE_OVERLAY_STATUS: 'LINKEDIN_MACHINE_OVERLAY_STATUS',
  LINKEDIN_MACHINE_SIGNAL: 'LINKEDIN_MACHINE_SIGNAL',
  LOGIN_STATUS: 'LOGIN_STATUS',
  METRICS_OPT_OUT: 'METRICS_OPT_OUT',
  MOST_RECENT_LOGIN: 'MOST_RECENT_LOGIN',
  NOTIFICATION_DATA: 'NOTIFICATION_DATA',
  NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
  OVERLAY_IS_OPAQUE: 'OVERLAY_IS_OPAQUE',
  RECC_PROGRESS: 'RECC_PROGRESS',
  REDDIT_ACTIVE_TAB_ID: 'REDDIT_ACTIVE_TAB_ID',
  REDDIT_MACHINE_CONTEXT: 'REDDIT_MACHINE_CONTEXT',
  REDDIT_MACHINE_KEY: 'REDDIT_MACHINE_KEY',
  REDDIT_MACHINE_OVERLAY_STATUS: 'REDDIT_MACHINE_OVERLAY_STATUS',
  REDDIT_MACHINE_SIGNAL: 'REDDIT_MACHINE_SIGNAL',
  SELECTED_FULL_SCREEN_MODAL: 'SELECTED_FULL_SCREEN_MODAL',
  SETUP_PROGRESS: 'SETUP_PROGRESS',
  SIDEBAR_BONUS_RECOMMENDATIONS_OPEN: 'SIDEBAR_BONUS_RECOMMENDATIONS_OPEN',
  SIDEBAR_FILTERS: 'SIDEBAR_FILTERS',
  SIDEBAR_FIRST_SCAN_MODAL_DISMISSED: 'SIDEBAR_FIRST_SCAN_MODAL_DISMISSED',
  SIDEBAR_HIDDEN: 'SIDEBAR_HIDDEN',
  SIDEBAR_IFRAME_HEIGHT: 'SIDEBAR_IFRAME_HEIGHT',
  SIDEBAR_MINIMIZED: 'SIDEBAR_MINIMIZED',
  SIDEBAR_SCROLL_POSITION: 'SIDEBAR_SCROLL_POSITION',
  SIDEBAR_TAB: 'SIDEBAR_TAB',
  SIDEBAR_TAB_POSITION: 'SIDEBAR_TAB_POSITION',
  SPEED: 'SPEED',
  SNAPCHAT_ACTIVE_TAB_ID: 'SNAPCHAT_ACTIVE_TAB_ID',
  SNAPCHAT_MACHINE_CONTEXT: 'SNAPCHAT_MACHINE_CONTEXT',
  SNAPCHAT_MACHINE_KEY: 'SNAPCHAT_MACHINE_KEY',
  SNAPCHAT_MACHINE_OVERLAY_STATUS: 'SNAPCHAT_MACHINE_OVERLAY_STATUS',
  SNAPCHAT_MACHINE_SIGNAL: 'SNAPCHAT_MACHINE_SIGNAL',
  STRAVA_ACTIVE_TAB_ID: 'STRAVA_ACTIVE_TAB_ID',
  STRAVA_MACHINE_CONTEXT: 'STRAVA_MACHINE_CONTEXT',
  STRAVA_MACHINE_KEY: 'STRAVA_MACHINE_KEY',
  STRAVA_MACHINE_OVERLAY_STATUS: 'STRAVA_MACHINE_OVERLAY_STATUS',
  STRAVA_MACHINE_SIGNAL: 'STRAVA_MACHINE_SIGNAL',
  TWITTER_ACTIVE_TAB_ID: 'TWITTER_ACTIVE_TAB_ID',
  TWITTER_MACHINE_CONTEXT: 'TWITTER_MACHINE_CONTEXT',
  TWITTER_MACHINE_KEY: 'TWITTER_MACHINE_KEY',
  TWITTER_MACHINE_OVERLAY_STATUS: 'TWITTER_MACHINE_OVERLAY_STATUS',
  TWITTER_MACHINE_SIGNAL: 'TWITTER_MACHINE_SIGNAL',
  TIKTOK_ACTIVE_TAB_ID: 'TIKTOK_ACTIVE_TAB_ID',
  TIKTOK_MACHINE_CONTEXT: 'TIKTOK_MACHINE_CONTEXT',
  TIKTOK_MACHINE_KEY: 'TIKTOK_MACHINE_KEY',
  TIKTOK_MACHINE_OVERLAY_STATUS: 'TIKTOK_MACHINE_OVERLAY_STATUS',
  TIKTOK_MACHINE_SIGNAL: 'TIKTOK_MACHINE_SIGNAL',
  USER_CONCERNS: 'USER_CONCERNS',
  USER_WELCOMED: 'USER_WELCOMED',
  VENMO_ACTIVE_TAB_ID: 'VENMO_ACTIVE_TAB_ID',
  VENMO_MACHINE_CONTEXT: 'VENMO_MACHINE_CONTEXT',
  VENMO_MACHINE_KEY: 'VENMO_MACHINE_KEY',
  VENMO_MACHINE_OVERLAY_STATUS: 'VENMO_MACHINE_OVERLAY_STATUS',
  VENMO_MACHINE_SIGNAL: 'VENMO_MACHINE_SIGNAL',
  YOUTUBE_ACTIVE_TAB_ID: 'YOUTUBE_ACTIVE_TAB_ID',
  YOUTUBE_MACHINE_CONTEXT: 'YOUTUBE_MACHINE_CONTEXT',
  YOUTUBE_MACHINE_KEY: 'YOUTUBE_MACHINE_KEY',
  YOUTUBE_MACHINE_OVERLAY_STATUS: 'YOUTUBE_MACHINE_OVERLAY_STATUS',
  YOUTUBE_MACHINE_SIGNAL: 'YOUTUBE_MACHINE_SIGNAL',
}

export const EXTENSION_MESSAGE_TYPES = {
  CANCEL_AUTOMATION: 'CANCEL_AUTOMATION',
  CANCEL_AUTOMATION_AND_UNDO: 'CANCEL_AUTOMATION_AND_UNDO',
  CHANGE_URL: 'CHANGE_URL',
  CHANGE_URL_AND_WAIT: 'CHANGE_URL_AND_WAIT',
  CLEAR_ACTIVE_TAB: 'CLEAR_ACTIVE_TAB',
  CLEAR_ICON_BADGE: 'CLEAR_ICON_BADGE',
  CLEAR_METRICS_OPT_OUT: 'CLEAR_METRICS_OPT_OUT',
  CLOSE_CURRENT_TAB: 'CLOSE_CURRENT_TAB',
  CLOSE_MODAL_ON_CURRENT_TAB: 'CLOSE_MODAL_ON_CURRENT_TAB',
  FB___APP_DISCONNECT_CONFIRMED: 'FB___APP_DISCONNECT_CONFIRMED',
  GET_ACTIVE_TAB: 'GET_ACTIVE_TAB',
  GET_ANONYMOUS_USER_ID: 'GET_ANONYMOUS_USER_ID',
  GET_COOKIE_STORE_ID_FROM_TAB_ID: 'GET_COOKIE_STORE_ID_FROM_TAB_ID',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  GET_CURRENT_TAB: 'GET_CURRENT_TAB',
  GET_CURRENT_TAB_URL: 'GET_CURRENT_TAB_URL',
  GET_EXTENSION_HOSTNAME: 'GET_EXTENSION_HOSTNAME',
  GET_IS_TAB_STILL_OPEN: 'GET_IS_TAB_STILL_OPEN',
  GET_LAST_EXTENSION_ERROR_STACK_TRACE: 'GET_LAST_EXTENSION_ERROR_STACK_TRACE',
  GET_LAST_FAILED_AUTOMATION_DATA: 'GET_LAST_FAILED_AUTOMATION_DATA',
  GET_METRICS_OPT_OUT: 'GET_METRICS_OPT_OUT',
  GET_MODAL_FOR_CURRENT_TAB: 'GET_MODAL_FOR_CURRENT_TAB',
  GET_NOTIFICATION_DATA: 'GET_NOTIFICATION_DATA',
  GET_TAB_ID: 'GET_TAB_ID',
  HAS_PERMISSIONS_FOR_PLATFORM: 'HAS_PERMISSIONS_FOR_PLATFORM',
  HIDE_SIDEBAR: 'HIDE_SIDEBAR',
  SEND_MINIMIZE_SIDEBAR_TO_BACKGROUND: 'SEND_MINIMIZE_SIDEBAR_TO_BACKGROUND',
  PASS_ON_MINIMIZE_SIDEBAR_TO_TAB: 'PASS_ON_MINIMIZE_SIDEBAR_TO_TAB',
  IS_SERVICE_WORKER_READY: 'IS_SERVICE_WORKER_READY',
  GET_ACTIVE_TAB_LANGUAGE_TO_BACKGROUND:
    'GET_ACTIVE_TAB_LANGUAGE_TO_BACKGROUND',
  PASS_ON_GET_ACTIVE_TAB_LANGUAGE_TO_TAB:
    'PASS_ON_GET_ACTIVE_TAB_LANGUAGE_TO_TAB',
  REFRESH_LOGIN_STATUS: 'REFRESH_LOGIN_STATUS',
  RELOAD_SIDEBAR: 'RELOAD_SIDEBAR',
  REMOTE_LOG: 'REMOTE_LOG',
  PASS_ON_SIDEBAR_HEIGHT_TO_TAB: 'PASS_ON_SIDEBAR_HEIGHT_TO_TAB',
  LOG_ERROR_MSG: 'LOG_ERROR_MSG',
  LOG_INFO_MSG: 'LOG_INFO_MSG',
  SEND_ALERT_NOTIFICATION: 'SEND_ALERT_NOTIFICATION',
  SEND_SIDEBAR_HEIGHT_TO_BACKGROUND: 'SEND_SIDEBAR_HEIGHT_TO_BACKGROUND',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_ANONYMOUS_USER_ID: 'SET_ANONYMOUS_USER_ID',
  SET_ICON_BADGE: 'SET_ICON_BADGE',
  SET_LAST_EXTENSION_ERROR_STACK_TRACE: 'SET_LAST_EXTENSION_ERROR_STACK_TRACE',
  SET_LAST_FAILED_AUTOMATION_DATA: 'SET_LAST_FAILED_AUTOMATION_DATA',
  SET_MACHINE_CONTEXT: 'SET_MACHINE_CONTEXT',
  SET_MACHINE_SIGNAL: 'SET_MACHINE_SIGNAL',
  SET_METRICS_OPT_OUT: 'SET_METRICS_OPT_OUT',
  SHOW_MODAL_ON_CURRENT_TAB: 'SHOW_MODAL_ON_CURRENT_TAB',
  UNHIDE_SIDEBAR: 'UNHIDE_SIDEBAR',
  UPDATE_MACHINE_CONTEXT: 'UPDATE_MACHINE_CONTEXT',
}

export enum DASHBOARD_TAB {
  NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS',
  TOOLS_AND_PLATFORMS = 'TOOLS_AND_PLATFORMS',
  PRIVACY = 'PRIVACY',
  SHARE = 'SHARE',
}

export enum OverlayModalType {
  CANCEL = 'CANCEL',
}

export enum GobyApiCookieType {
  ACCESS_JWT = 'access_jwt',
  ACCESS_JWT_CSRF = 'csrf_access_jwt',
  ANONYMOUS_USER_ID = 'anonymous_user_id',
  REFRESH_JWT = 'refresh_jwt',
  REFRESH_JWT_CSRF = 'csrf_refresh_jwt',
  EXTENSION_ID = 'extension_id',
  PREVIEW_KEY = 'preview_key',
}

export enum PrivacyPartyModalType {
  DELETE_PROGRESS_CONFIRMATION = 'DELETE_PROGRESS_CONFIRMATION',
  FIRST_TIME_USER = 'FIRST_TIME_USER',
  FULL_ACCESS_STARTS_NOW = 'FULL_ACCESS_STARTS_NOW',
  GO_TO_DEEPLINK = 'GO_TO_DEEPLINK',
  PERMISSIONS_PROMPT = 'PERMISSIONS_PROMPT',
  TRIAL_STARTED = 'TRIAL_STARTED',
}

export enum FullScreenModalType {
  FACEBOOK_PHOTO_UNTAGGING_MODAL = 'FACEBOOK_PHOTO_UNTAGGING_MODAL',
  FB_UNFRIEND_MODAL = 'FB_UNFRIEND_MODAL',
}

export enum RecommendationFilterType {
  NOT_REVIEWED = 'NOT_REVIEWED',
}

export enum UserSettingsModalType {
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  CHANGE_NAME = 'CHANGE_NAME',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CONFIRM_2FA_APP = 'CONFIRM_2FA_APP',
  CONFIRM_2FA_SMS = 'CONFIRM_2FA_SMS',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  ENROLL_2FA_APP = 'ENROLL_2FA_APP',
  ENROLL_2FA_SMS = 'ENROLL_2FA_SMS',
}

export const ALARM_NAME = {
  REFRESH_NOTIFICATIONS: 'REFRESH_NOTIFICATIONS',
  UPSELL_NOTIFICATION_DAY_ONE: 'UPSELL_NOTIFICATION_DAY_ONE',
  UPSELL_NOTIFICATION_DAY_THREE: 'UPSELL_NOTIFICATION_DAY_THREE',
  UPSELL_NOTIFICATION_DAY_SEVEN: 'UPSELL_NOTIFICATION_DAY_SEVEN',
  RUN_AUTOSCAN: 'RUN_AUTOSCAN',
}

export enum ConcernTag {
  ACCOUNT_SECURITY = 'Account security',
  ACCOUNT_SEPARATION = 'Account separation',
  CHILD_SAFETY = 'Child safety',
  DATA_MISUSE = 'Data misuse',
  DIGITAL_WELLNESS = 'Digital wellness',
  ONLINE_ABUSE = 'Online abuse',
  PHYSICAL_SAFETY = 'Physical safety',
}

export const DEBUG_KEYBOARD_CODE = [68, 69, 66, 85, 71]
