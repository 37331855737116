import {
  VenmoRecommendationKey,
  RecommendationTaskType,
} from '../../utils/enums'
import metadata from '../metadata/venmo.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import specs from '../specs/venmo.yaml'

const VENMO_RECOMMENDATION_KEYS = [
  VenmoRecommendationKey.VENMO_PAYMENT_PRIVACY,
  VenmoRecommendationKey.VENMO_AUTOMATIC_FRIENDING,
  VenmoRecommendationKey.VENMO_FRIENDS_LIST,
  VenmoRecommendationKey.VENMO_REMEMBERED_DEVICES,
  VenmoRecommendationKey.VENMO_NOTIFICATIONS,
]

const VENMO_RECOMMENDATIONS_REGISTRY: Record<
  VenmoRecommendationKey,
  Recommendation
> = {
  [VenmoRecommendationKey.VENMO_PAYMENT_PRIVACY]: {
    ...parseSpec(specs[VenmoRecommendationKey.VENMO_PAYMENT_PRIVACY]),
    key: VenmoRecommendationKey.VENMO_PAYMENT_PRIVACY,
  },
  [VenmoRecommendationKey.VENMO_AUTOMATIC_FRIENDING]: {
    ...parseSpec(specs[VenmoRecommendationKey.VENMO_AUTOMATIC_FRIENDING]),
    key: VenmoRecommendationKey.VENMO_AUTOMATIC_FRIENDING,
  },
  [VenmoRecommendationKey.VENMO_FRIENDS_LIST]: {
    ...parseSpec(specs[VenmoRecommendationKey.VENMO_FRIENDS_LIST]),
    key: VenmoRecommendationKey.VENMO_FRIENDS_LIST,
  },
  [VenmoRecommendationKey.VENMO_REMEMBERED_DEVICES]: {
    ...parseSpec(specs[VenmoRecommendationKey.VENMO_REMEMBERED_DEVICES]),
    key: VenmoRecommendationKey.VENMO_REMEMBERED_DEVICES,
  },
  [VenmoRecommendationKey.VENMO_NOTIFICATIONS]: {
    ...parseSpec(specs[VenmoRecommendationKey.VENMO_NOTIFICATIONS]),
    key: VenmoRecommendationKey.VENMO_NOTIFICATIONS,
  },
}

export const venmoRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => VENMO_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () => VENMO_RECOMMENDATION_KEYS,
  getChecklistKeys: () =>
    VENMO_RECOMMENDATION_KEYS.filter(
      (key) => !VENMO_RECOMMENDATIONS_REGISTRY[key].taskType,
    ),
  getContentTaskKeys: () =>
    VENMO_RECOMMENDATION_KEYS.filter(
      (key) =>
        VENMO_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.CONTENT,
    ),
  getExperienceTaskKeys: () =>
    VENMO_RECOMMENDATION_KEYS.filter(
      (key) =>
        VENMO_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.EXPERIENCE,
    ),
  getRecommendationsRegistry: () => VENMO_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
