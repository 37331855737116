import React from 'react'

import { InstagramAccountVisibility } from '$extensionSrc/automations/instagram/accountEnums'
import PublicIcon from '$extensionSrc/icons/public-icon.svg'

import './InstagramAccountTypeSubheader.scss'

export default function InstagramAccountTypeSubheader({ type }) {
  switch (type) {
    case InstagramAccountVisibility.PUBLIC:
      return (
        <div className="InstagramPublicSubheader subheader">
          <div className="body2 text">Best for public accounts</div>
          <PublicIcon className="icon public-icon" />
        </div>
      )
    default:
  }
  return null
}
