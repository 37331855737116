import React from 'react'

import { Avatar, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { EnterprisePlansQuery } from '../../../../__generated__/graphql'

export default function Item({
  enterprisePlan,
  open,
}: {
  // @TODO: Figure out a way to have better generated typing
  enterprisePlan: EnterprisePlansQuery['enterprisePlans']['items'][0]
  open: boolean
}) {
  const theme = useTheme()

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton href={`/enterprise/${enterprisePlan.id}`}>
        <Avatar
          sx={[
            { backgroundColor: theme.palette.primary.light },
            open ? { mr: 3 } : { mr: 'auto' },
          ]}
        >
          {enterprisePlan.organizationName.charAt(0)}
        </Avatar>
        <ListItemText
          primary={enterprisePlan.organizationName}
          sx={open ? { opacity: 1 } : { opacity: 0 }}
        />
      </ListItemButton>
    </ListItem>
  )
}
