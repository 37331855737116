import {
  YoutubeRecommendationKey,
  RecommendationTaskType,
} from '../../utils/enums'
import metadata from '../metadata/youtube.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import specs from '../specs/youtube.yaml'

const YOUTUBE_RECOMMENDATION_KEYS = [
  YoutubeRecommendationKey.YOUTUBE_PUSH_NOTIFICATIONS,
  YoutubeRecommendationKey.YOUTUBE_EMAIL_NOTIFICATIONS,
  YoutubeRecommendationKey.YOUTUBE_CONNECTED_APPS,
  YoutubeRecommendationKey.YOUTUBE_SUBSCRIPTION_PRIVACY,
  YoutubeRecommendationKey.YOUTUBE_PURCHASE,
]

const YOUTUBE_RECOMMENDATIONS_REGISTRY: Record<
  YoutubeRecommendationKey,
  Recommendation
> = {
  [YoutubeRecommendationKey.YOUTUBE_PUSH_NOTIFICATIONS]: {
    ...parseSpec(specs[YoutubeRecommendationKey.YOUTUBE_PUSH_NOTIFICATIONS]),
    key: YoutubeRecommendationKey.YOUTUBE_PUSH_NOTIFICATIONS,
  },
  [YoutubeRecommendationKey.YOUTUBE_EMAIL_NOTIFICATIONS]: {
    ...parseSpec(specs[YoutubeRecommendationKey.YOUTUBE_EMAIL_NOTIFICATIONS]),
    key: YoutubeRecommendationKey.YOUTUBE_EMAIL_NOTIFICATIONS,
  },
  [YoutubeRecommendationKey.YOUTUBE_CONNECTED_APPS]: {
    ...parseSpec(specs[YoutubeRecommendationKey.YOUTUBE_CONNECTED_APPS]),
    key: YoutubeRecommendationKey.YOUTUBE_CONNECTED_APPS,
  },
  [YoutubeRecommendationKey.YOUTUBE_SUBSCRIPTION_PRIVACY]: {
    ...parseSpec(specs[YoutubeRecommendationKey.YOUTUBE_SUBSCRIPTION_PRIVACY]),
    key: YoutubeRecommendationKey.YOUTUBE_SUBSCRIPTION_PRIVACY,
  },
  [YoutubeRecommendationKey.YOUTUBE_PURCHASE]: {
    ...parseSpec(specs[YoutubeRecommendationKey.YOUTUBE_PURCHASE]),
    key: YoutubeRecommendationKey.YOUTUBE_PURCHASE,
  },
}

export const youtubeRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => YOUTUBE_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () => YOUTUBE_RECOMMENDATION_KEYS,
  getChecklistKeys: () =>
    YOUTUBE_RECOMMENDATION_KEYS.filter(
      (key) => !YOUTUBE_RECOMMENDATIONS_REGISTRY[key].taskType,
    ),
  getContentTaskKeys: () =>
    YOUTUBE_RECOMMENDATION_KEYS.filter(
      (key) =>
        YOUTUBE_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.CONTENT,
    ),
  getExperienceTaskKeys: () =>
    YOUTUBE_RECOMMENDATION_KEYS.filter(
      (key) =>
        YOUTUBE_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.EXPERIENCE,
    ),
  getRecommendationsRegistry: () => YOUTUBE_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
